import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeft, CreditCard, History, Warning } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Typography,
  IconButton,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Chip,
  Stack
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserBillingDetails } from "../../features/auth/authSlice";
import { selectCurrentUserBillingDetails } from "../../features/auth/authSelectors";
import dayjs from "dayjs";
import CardLists from "./CardLists";
import useThemeColor from "../../utils/useTheme";

const ManageBilling = () => {
  const navigate = useNavigate();
  const styles = useThemeColor();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const currentSubscription = useSelector(selectCurrentUserBillingDetails);

  useEffect(() => {
    dispatch(fetchUserBillingDetails());
  }, [dispatch]);

  const handleCancelSubscription = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSnackbarMessage("Subscription cancelled successfully.");
      setOpenSnackbar(true);
    }, 1500);
  };

  const renderBillingHistoryTable = () => {
    const history = currentSubscription?.billingHistory || [];
    return (
      <Card
        sx={{ boxShadow: "none", mt: 4, mx: 3, background: styles.background }}
        elevation={2}
      >
        <CardContent>
          <Box display="flex" alignItems="center" mb={3}>
            <History sx={{ mr: 1, color: "#1976d2" }} />
            <Typography variant="h6" fontWeight={500} color={styles.color}>
              Billing History
            </Typography>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 600, color: styles.color }}>
                    Amount
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, color: styles.color }}>
                    Status
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, color: styles.color }}>
                    Date
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, color: styles.color }}>
                    Description
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {history.map((record, index) => (
                  <TableRow key={index} hover>
                    <TableCell sx={{ color: styles.color }}>
                      ${record.amount}
                    </TableCell>
                    <TableCell sx={{ color: styles.color }}>
                      <Chip
                        label={record.status?.toUpperCase()}
                        color={record.status === "paid" ? "success" : "error"}
                        size="small"
                      />
                    </TableCell>
                    <TableCell sx={{ color: styles.color }}>
                      {dayjs(record.purchaseDate).format("D MMM YYYY")}
                    </TableCell>
                    <TableCell sx={{ color: styles.color }}>
                      <Stack spacing={1}>
                        {record.product.map((product, idx) => (
                          <Typography key={idx} variant="body2">
                            {product.description}
                          </Typography>
                        ))}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    );
  };

  return (
    <Box
      display={"flex"}
      width="100%"
      flexDirection="column"
      color={styles.color}
      backgroundColor={styles.background}
      overflow="auto"
    >
      {/* Header */}
      <Box display="flex" alignItems="center" gap={3} p="32px 20px 0">
        <IconButton onClick={() => navigate("/profile")}>
          <ChevronLeft sx={{ height: 35, width: 35, fill: styles.color }} />
        </IconButton>
        <Typography variant="h5" fontWeight="500">
          Manage Billing
        </Typography>
      </Box>

      {/* Subscription Info Card */}
      {["active", "canceled"].includes(
        currentSubscription?.subscriptionStatus
      ) ? (
        <Card
          sx={{
            mx: 3,
            mt: 4,
            background: styles.background,
            color: styles.color
          }}
          elevation={2}
        >
          <CardContent>
            <Box display="flex" alignItems="center" mb={3}>
              <CreditCard sx={{ mr: 1, color: "#1976d2" }} />
              <Typography variant="h6">Current Subscription</Typography>
            </Box>
            <Stack spacing={2}>
              <Box
                display="flex"
                gap={10}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle1" color={styles.color}>
                  Product
                </Typography>
                <Typography variant="body1" fontWeight="500">
                  {currentSubscription?.currentSubscription?.[0]?.productName}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle1" color={styles.color}>
                  Price
                </Typography>
                <Typography variant="body1" fontWeight="500">
                  $
                  {+currentSubscription?.currentSubscription?.[0]?.amount / 100}{" "}
                  / {currentSubscription?.currentSubscription?.[0]?.interval}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle1" color={styles.color}>
                  Billing Cycle Start
                </Typography>
                <Typography variant="body1">
                  {dayjs(currentSubscription?.billingCycleStartDate).format(
                    "D MMM YYYY"
                  )}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle1" color={styles.color}>
                  Next Billing Date
                </Typography>
                <Typography variant="body1">
                  {dayjs(currentSubscription?.nextBillingDate).format(
                    "D MMM YYYY"
                  )}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => setOpenModal(true)}
                  disabled={
                    loading ||
                    currentSubscription?.subscriptionStatus === "canceled"
                  }
                  startIcon={loading ? <CircularProgress size={20} /> : null}
                  sx={{
                    mt: 2,
                    width: "35%",
                    textTransform: "none",
                    "&:disabled": {
                      backgroundColor: "#A7C8E9",
                      color: "#64AEDD",
                      pointerEvents: "none"
                    }
                  }}
                >
                  {currentSubscription?.subscriptionStatus === "canceled"
                    ? "Cancelled"
                    : "Cancel Subscription"}
                </Button>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      ) : (
        <Alert severity="info" sx={{ mx: 3, mt: 4 }}>
          No active subscription found.
        </Alert>
      )}

      <CardLists />
      {renderBillingHistoryTable()}

      {/* Cancel Subscription Dialog */}
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        PaperProps={{
          sx: {
            borderRadius: 2,
            width: "400px"
          }
        }}
      >
        <DialogTitle sx={{ textAlign: "center", pt: 4 }}>
          <Warning color="error" sx={{ fontSize: 48, mb: 2 }} />
          <Typography variant="h6" fontWeight="600">
            Cancel Subscription?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText textAlign="center">
            This action is permanent and cannot be undone. You will lose access
            to premium features at the end of your billing period.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 3, flexDirection: "column", gap: 1 }}>
          <Button
            variant="contained"
            color="error"
            fullWidth
            onClick={handleCancelSubscription}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              "Yes, Cancel Subscription"
            )}
          </Button>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => setOpenModal(false)}
          >
            No, Keep My Subscription
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={openSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarMessage.includes("success") ? "success" : "error"}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ManageBilling;
