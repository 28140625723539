import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as StreaksIcon } from "../../assets/streaks.svg";
import {
  selectAuthLoading,
  selectCurrentUser,
  selectUserPoints
} from "../../features/auth/authSelectors";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ChevronLeft } from "@mui/icons-material";
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  Avatar,
  Box,
  styled,
  Alert,
  useMediaQuery,
  IconButton,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";
import useThemeColor from "../../utils/useTheme";
import dayjs from "dayjs";
import { getPointList } from "../../features/auth/authSlice";
import { useTheme } from "@emotion/react";

function getLastSixMonths() {
  const months = [];
  for (let i = 0; i < 6; i++) {
    const date = dayjs().subtract(i, "month");
    const name = date.format("MMMM YYYY");
    const value = date.format("MM/YYYY");
    months.push({ name, value });
  }
  return months;
}

const PointsStats = ({ setOpenSubscriptionModal, levelId }) => {
  const userData = useSelector(selectCurrentUser);
  const styles = useThemeColor();
  const lastSixMonths = getLastSixMonths();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const matches = useMediaQuery("(max-width:590px)");
  const [value, setValue] = useState(lastSixMonths?.[0]?.value);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !(
        userData?.isAdmin === 1 ||
        ["Coach", "Company"].includes(userData?.userType)
      ) &&
      !userData?.isSubscriptionActive &&
      (userData?.isTrainingFreeTrialExpired ||
        userData?.isDiaryFreeTrialExpired)
    ) {
      setOpenSubscriptionModal(true);
    }
  }, [userData]);
  const userPointsData = useSelector(selectUserPoints);
  useEffect(() => {
    dispatch(
      getPointList({
        month: value?.split("/")[0],
        year: value?.split("/")[1],
        levelId
      })
    );
  }, [dispatch, value]);
  return !(
    userData?.isAdmin === 1 || ["Coach", "Company"].includes(userData?.userType)
  ) &&
    !userData?.isSubscriptionActive &&
    (userData?.isTrainingFreeTrialExpired ||
      userData?.isDiaryFreeTrialExpired) ? (
    <></>
  ) : (
    <Box
      color={styles.color}
      backgroundColor={styles.background}
      display={"flex"}
      padding={"0 20px"}
      overflow="auto"
      flexDirection="column"
    >
      <Box display="flex" alignItems="center" gap={3} p="32px 0 0 20px">
        <IconButton onClick={() => navigate("/profile")}>
          <ChevronLeft
            sx={{ height: "35px", width: "35px", fill: styles.color }}
            height={35}
            width={35}
          />
        </IconButton>
        <Typography
          sx={{
            ...(matches && { display: "flex", justifyContent: "center" })
          }}
          fontSize="24px"
        >
          Stats
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={"column"}
      >
        <FormControl
          sx={{
            width: { xs: "100%", md: "35%" },
            marginTop: "15px",
            svg: {
              fill: styles.color
            },
            ".MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: styles.lightGreyColor
              },
              "&:hover fieldset": {
                borderColor: styles.color
              },
              "&.Mui-focused fieldset": {
                borderColor: styles.color
              }
            }
          }}
        >
          <Select
            labelId="range-label"
            name="range"
            value={value}
            MenuProps={{
              MenuListProps: {
                sx: {
                  padding: 0,
                  ".Mui-selected": {
                    color: `${styles.background} !important`,
                    background: `${styles.color} !important`
                  }
                }
              }
            }}
            sx={{
              color: styles.color,
              marginTop: 0,
              paddingTop: 0
            }}
            onChange={(e) => setValue(e.target.value)}
          >
            {lastSixMonths.map((item) => (
              <MenuItem
                key={item.value}
                sx={{
                  backgroundColor: styles.background,
                  color: styles.color,
                  paddingY: 1,
                  "&:hover": {
                    backgroundColor: styles.color,
                    color: styles.background
                  }
                }}
                value={item.value}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box
          mt={3}
          display="flex"
          gap={3}
          width={isMobile ? "100%" : "70%"}
          justifyContent="center"
          alignItems="center"
          flexDirection={"column"}
        >
          <Box
            sx={{
              background: "#4CBD6B",
              padding: "24px",
              justifyContent: "center",
              display: "flex",
              width: "100%",
              alignItems: "center",
              flexDirection: "column",
              borderRadius: "12px",
              gap: "4px"
            }}
          >
            <Typography fontSize={"12px"}>Total points earned</Typography>
            <Typography fontWeight={600} fontSize={"30px"}>
              {Math.round(userPointsData?.totalPoints || 0)} pts
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={"center"}
              width={"40px"}
              color="#14142B"
              sx={{ background: "#FFE1CA" }}
              // padding={"0 10px"}
              borderRadius="100px"
            >
              <StreaksIcon height={14} width={14} />
              <Typography fontSize="12px"> 20</Typography>
            </Box>
          </Box>
          <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={3}>
            {userPointsData?.pointsDetails?.length ? (
              userPointsData?.pointsDetails?.map((item) => (
                <Box
                  sx={{
                    background: "#333333",
                    padding: "35px 16px",
                    borderRadius: "20px"
                  }}
                  display="flex"
                  gap={2}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Box>
                    {/* display="flex" flexDirection="column" gap={0.3}> */}
                    <Typography fontWeight={600} fontSize={"16px"}>
                      {item?.description}
                    </Typography>
                    <Typography color="#8E8E8E" fontSize={"13px"}>
                      {dayjs(item?.currentDayDate).format("D MMM YYYY")}
                    </Typography>
                  </Box>
                  <Typography fontWeight={600}>{item?.points} pts</Typography>
                </Box>
              ))
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PointsStats;
