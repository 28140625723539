import createApi from "../utils/axiosInstance";

// Fetch particular user
export const fetchUser = async (userId, date) => {
  try {
    const api = createApi(false); // Token required
    const response = await api.get(
      "/user/getUserById/" + userId + `?date=${date}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Fetching user data failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const fetchUserPoints = async (month, year, levelId) => {
  try {
    const api = createApi(true); // Token required
    const response = await api.get(
      `user/total-points?month=${month}&year=${year}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Fetching user points failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

// Complete user profile
export const completeUserProfile = async (userData, userId) => {
  try {
    const api = createApi(false);
    const response = await api.post(`/user/editProfile/${userId}`, userData);
    if (response.data.user) {
      const { user, token } = response.data;
      // const hours = expiresIn?.split("h")[0];
      // const expirationTime = new Date().getTime() + hours * 60 * 60 * 1000;
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
      // localStorage.setItem("expirationTime", expirationTime);
    }
    return response.data;
  } catch (error) {
    console.error(
      "Completing profile failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error.message;
  }
};

// Update user password
export const updatePassword = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.post(`/user/change-password`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Changing profile password failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error.message;
  }
};

export const deleteUser = async (userId) => {
  try {
    const api = createApi(true);
    const response = await api.delete(`/admin/user/delete-user/` + userId);
    return response.data;
  } catch (error) {
    console.error(
      "Deleting profile  failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error.message;
  }
};

// Get user type
export const getUserType = async () => {
  try {
    const api = createApi(false);
    const response = await api.get("/user/getUserTypeList");
    return response.data;
  } catch (error) {
    console.error(
      "Fetching user type failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error.message;
  }
};

// Subscribe
export const addSubscription = async (mode, email) => {
  try {
    const api = createApi(true);
    const response = await api.post(
      `/payment/subscription/create-subscription?interval=${mode}`,
      { email }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Subscription failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error.message;
  }
};

export const cancelSubscription = async (subscriptionId) => {
  try {
    const api = createApi(true);
    const response = await api.post(
      `/payment/subscription/cancel-subscription`,
      { subscriptionId }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Subscription failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error.message;
  }
};

export const getUserSubscription = async (mode, email) => {
  try {
    const api = createApi(true);
    const response = await api.post(
      `/payment/subscription/get-customer-subscription`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Subscription failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error.message;
  }
};
