import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

// Need to extend dayjs with the isSameOrBefore plugin
dayjs.extend(isSameOrBefore);

const generateDateArray = (startDate) => {
  // If startDate is null/undefined, return empty array
  if (!startDate) return [];

  const dates = [];
  // Ensure we create a new dayjs object from the ISO string
  const start = dayjs(startDate);
  const current = dayjs();

  let currentDate = start;

  while (currentDate.isSameOrBefore(current, "day")) {
    dates.push({
      title: currentDate.format("MMM D"), // Format as 'Nov 4'
      value: currentDate.format("YYYY-MM-DD") // This will be a dayjs object
    });
    currentDate = currentDate.add(1, "day");
  }

  return dates;
};

const addMissingDates = (data, startDate, levelData) => {
  const allDates = generateDateArray(startDate); // Get the dates array from the provided startDate
  const existingDates = data?.map((item) =>
    dayjs(item.currentDayDate).format("YYYY-MM-DD")
  );
  const updatedData = [...data];
  const existingTotal = updatedData?.[0]?.outOf800g;
  const existingTotalProtein = updatedData?.[0]?.outOfProtein;
  // Iterate over the generated dates and add missing ones
  allDates?.forEach((date) => {
    if (!existingDates?.includes(date.value)) {
      updatedData.push({
        currentDayDate: date.value, // Missing date
        entry800g: [],
        protein: [],
        otherFood: [],
        levelName: levelData?.levelName,
        levelId: levelData?.levelId,
        outOf800g: existingTotal || 800,
        outOfProtein: existingTotalProtein || 100
      });
    }
  });

  return updatedData;
};

export { addMissingDates, generateDateArray };
