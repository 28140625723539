import React from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  IconButton,
  Grid
} from "@mui/material";
import { ReactComponent as ChefIcon } from "../../assets/chef.svg";
import { ReactComponent as CoachIcon } from "../../assets/coach.svg";
import { ReactComponent as ConstantIcon } from "../../assets/constant.svg";
import { ReactComponent as Lvl1Icon } from "../../assets/lvl1.svg";
import { ReactComponent as Lvl2Icon } from "../../assets/lvl2.svg";
import { ReactComponent as Lvl3Icon } from "../../assets/lvl3.svg";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

const AchievementModal = ({ isOpen, setIsOpen }) => {
  const closeModal = () => setIsOpen(false);

  const achievements = [
    {
      title: "Level 1",
      description:
        "One hash mark each for the completion of #800gChallenge®, Lazy Macros®, and Three Pillars Method® programs.",
      icon: Lvl1Icon
    },
    {
      title: "Level 2",
      description:
        "One hash mark each for the completion of #800gChallenge®, Lazy Macros®, and Three Pillars Method® programs.",
      icon: Lvl2Icon
    },
    {
      title: "Level 3",
      description:
        "One hash mark each for the completion of #800gChallenge®, Lazy Macros®, and Three Pillars Method® programs.",
      icon: Lvl3Icon
    },
    {
      title: "Coach",
      description: (
        <>
          Three Pillars Method® Coach. Fulfilled the requirements of the{" "}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to="https://optimizemenutrition.com/masterclasscoaches/"
            style={{ color: "#FCFCFC", textDecoration: "underline" }}
          >
            Coaches Masterclass
          </Link>{" "}
          and{" "}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to="https://optimizemenutrition.com/become-a-nutrition-coach/"
            style={{ color: "#FCFCFC", textDecoration: "underline" }}
          >
            affiliation
          </Link>
          .
        </>
      ),
      icon: CoachIcon
    },
    {
      title: "Chef",
      description:
        "Reflects the number of submitted Meal of the Days (MOD) posted publicly in the app.",
      icon: ChefIcon
    },
    {
      title: "Consistency",
      description:
        "Appears when you've logged for at least 11 of the last 14 days.",
      icon: ConstantIcon
    }
  ];

  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      open={isOpen}
      onClose={closeModal}
    >
      <Box
        sx={{
          backgroundColor: "#1C1C1C",
          color: "#FCFCFC",
          borderRadius: "8px",
          width: "80%",
          maxWidth: "700px",
          padding: "40px",
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={closeModal}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "#FCFCFC"
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Header */}
        <Typography
          variant="h5"
          component="h2"
          sx={{ textAlign: "center", marginBottom: "20px" }}
        >
          Achievement
        </Typography>

        {/* Content */}
        <Grid container spacing={2}>
          {achievements.map((item, index) => (
            <Grid
              item
              xs={12}
              key={index}
              sx={{ display: "flex", gap: "20px", alignItems: "center" }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap="10px"
                position="relative" // Make the Box a positioning context
                sx={{ width: "80px", height: "80px" }} // Add this line
              >
                <item.icon style={{ width: "80px", height: "80px" }} />
              </Box>
              <Box>
                <Typography variant="h6" sx={{ margin: 0, fontSize: "18px" }}>
                  {item.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ margin: 0, fontSize: "14px", color: "#AAAAAA" }}
                >
                  {item.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Modal>
  );
};

export default AchievementModal;
