import React from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTheme } from "@emotion/react";
import useThemeColor from "../../utils/useTheme";

const WeekView = ({
  activeDay,
  setActiveDay,
  startDay,
  setStartDay,
  trainingData,
  allCompletedData,
  handleDayClick,
  handleNext,
  handlePrevious,
  visibleDays,
  visibleDaysCount,
  days
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const styles = useThemeColor();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderBottom={`1px solid ${styles.lightGreyColor}`}
      sx={{
        backgroundColor: styles.background,
        padding: { md: "0 0px 10px 0", lg: "0 10px 20px 10px" },
        gap: { md: "2px", lg: "30px" },
        ...(isMobile && { gap: "8px", paddingBottom: "10px" })
      }}
    >
      <IconButton
        sx={{ color: styles.color, p: { md: 0, lg: 1 } }}
        aria-label="previous"
        onClick={handlePrevious}
        disabled={startDay === 1}
      >
        <ArrowBackIosIcon />
      </IconButton>

      {visibleDays?.map(({ title, value }) => {
        return (
          <Box
            display="flex"
            onClick={() => handleDayClick(value)} // Only handle click if day is enabled
            alignItems="center"
            flexDirection="column"
            gap={1}
            borderRadius={100}
            height={80}
            sx={{
              cursor: "pointer",
              padding: { sm: "5px", lg: "8px", md: "8px 3px" },
              ...(isMobile && { padding: "5px" }),
              color: activeDay === value ? "white" : "#9e9e9e",
              backgroundColor:
                activeDay === value ? styles.dayHighlighter : "transparent"
            }}
            key={title}
          >
            <Typography color={styles.color} fontSize="13px">
              {title?.split(" ")?.[0]}
            </Typography>
            <Button
              sx={{
                ":disabled": {
                  color: styles.disabledColor
                },
                ...(allCompletedData?.includes(value) && {
                  border: "2px solid #368AD0"
                }),
                color: activeDay === value ? "#FCFCFC" : styles.disabledColor,
                backgroundColor:
                  activeDay === value ? "#368AD0" : "transparent",
                minWidth: "40px",
                borderRadius: "50%",
                padding: "6px"
              }}
            >
              {title?.split(" ")?.[1]}
            </Button>
          </Box>
        );
      })}

      <IconButton
        sx={{ color: styles.color, p: { md: 0, lg: 1 } }}
        aria-label="next"
        onClick={handleNext}
        disabled={startDay + visibleDaysCount - 1 >= days?.length}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export default WeekView;
