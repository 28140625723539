import {
  Avatar,
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, { useEffect } from "react";
import {
  Add,
  Favorite,
  FavoriteBorder,
  TurnedIn,
  TurnedInNot
} from "@mui/icons-material";
import AddMod from "../Modals/AddMOD";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentDateMeal } from "../../features/mod/modSlice";
import dayjs from "dayjs";
import {
  selectIsFavorite,
  selectIsLiked,
  selectMealData
} from "../../features/mod/modSelectors";
import getInitials from "../../utils/getInitials";
import { convertTextToLink } from "../../utils/getUrlFromText";
import { favoriteMeal, likeMeal } from "../../api/modApi";
import useSignedUrl from "../../utils/useSignedUrl";
import useThemeColor from "../../utils/useTheme";
import { selectCurrentTheme } from "../../features/theme/themeSelectors";

const Mod = ({ setOpenSubscriptionModal }) => {
  const [isAddingMeal, setIsAddingMeal] = React.useState(false);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const currentMeal = useSelector(selectMealData);
  const isLiked = useSelector(selectIsLiked);
  const isFavorite = useSelector(selectIsFavorite);
  const styles = useThemeColor();
  const currentTheme = useSelector(selectCurrentTheme);
  const theme = useTheme(); // Use theme hook to get the theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Correctly access breakpoints
  React.useEffect(() => {
    if (
      !(user?.isAdmin === 1 || ["Coach", "Company"].includes(user?.userType)) &&
      !user?.isSubscriptionActive &&
      (user?.isTrainingFreeTrialExpired || user?.isDiaryFreeTrialExpired)
    ) {
      setOpenSubscriptionModal(true);
    }
  }, [user]);
  useEffect(() => {
    dispatch(getCurrentDateMeal({ currentDate: dayjs().format("YYYY-MM-DD") }));
  }, [dispatch]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const scrollableElement = document.getElementById("dashboard-main"); // or document.body
    scrollableElement.scrollTo(0, 0);
  }, []);

  const mealUserImageSrc = useSignedUrl(
    currentMeal?.userData?.[0]?.profilePic ||
      currentMeal?.adminUserData?.[0]?.profilePic,
    process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
  );

  const mealImageSrc = useSignedUrl(
    currentMeal?.mealImage,
    process.env.REACT_APP_BACKEND_MEAL_IMAGE_LOCATION
  );

  return !(
    user?.isAdmin === 1 || ["Coach", "Company"].includes(user?.userType)
  ) &&
    !user?.isSubscriptionActive &&
    (user?.isTrainingFreeTrialExpired || user?.isDiaryFreeTrialExpired) ? (
    <></>
  ) : (
    <Box>
      {isAddingMeal ? (
        <AddMod isAddingMeal={isAddingMeal} setIsAddingMeal={setIsAddingMeal} />
      ) : (
        <Box
          pb={10}
          sx={{
            padding: isMobile ? "0 0 115px 0" : "40px 32px"
          }}
        >
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            justifyContent="space-between"
            alignItems={isMobile ? "flex-start" : "center"}
          >
            <Box
              display="flex"
              alignItems="center"
              gap="30px"
              sx={{
                ...(isMobile && {
                  width: "100%",
                  padding: "16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "10px",
                  background: "#1F2937",
                  color: styles.color // Apply color to the entire box in mobile view
                })
              }}
            >
              <Box display="flex" alignItems="center" gap="10px">
                {/* <Box>
                  {imgSrc ? (
                    <Box
                      component="img"
                      height={54}
                      borderRadius="50%"
                      width={54}
                      src={imgSrc}
                      alt="avatar"
                    />
                  ) : (
                    <Avatar sx={{ width: 40, height: 40 }}>
                      {getInitials(user?.fullName)}
                    </Avatar>
                  )}
                </Box> */}
                <Box>
                  <Typography
                    fontSize={isMobile ? "14px" : "30px"}
                    fontWeight={400}
                    color={styles.modLabel}
                  >
                    Meal of the Day (MOD)
                  </Typography>
                  <Typography
                    color={isMobile ? "#FCFCFC" : styles.color}
                    fontSize={isMobile ? "18px" : "24px"}
                    fontWeight={isMobile ? 600 : 500}
                  >
                    {dayjs().format("D MMM YYYY")}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  // onClick={() => goBack(false)}
                  sx={{
                    textTransform: "none",
                    color: "#368AD0",
                    borderColor: "#368AD0",
                    mt: 1, // Reduced margin top
                    gap: "8px",
                    borderRadius: "4px",
                    alignSelf: "flex-end" // Align button to the end horizontally
                  }}
                >
                  Refer a friend
                </Button>
              </Box>
            </Box>

            {/* For Web view, Submit MOD Button remains here */}
            {!isMobile && (
              <Button
                onClick={() => setIsAddingMeal(!isAddingMeal)}
                sx={{
                  textTransform: "none",
                  background: "#368AD0",
                  color: "#fcfcfc",
                  height: "40px",
                  padding: "12px 24px",
                  gap: "8px",
                  borderRadius: "4px"
                }}
              >
                <Add /> Submit MOD
              </Button>
            )}
          </Box>

          {currentMeal?._id ? (
            <Box
              mt={isMobile ? 2 : 3}
              gap={isMobile ? 2 : 4}
              display="flex"
              padding={isMobile ? "16px" : ""}
              flexDirection={isMobile ? "column" : "row"}
            >
              <Box width={isMobile ? "100%" : "50%"}>
                <Box
                  component="img"
                  alt="meal"
                  src={mealImageSrc}
                  width={"100%"}
                  sx={
                    isMobile
                      ? { padding: "24px 16px" }
                      : { "-webkit-user-drag": "none" }
                  }
                  borderRadius={10}
                />
              </Box>

              <Box width="100%">
                <Box
                  display="flex"
                  flexDirection={isMobile ? "column" : "row"}
                  gap="20px"
                  mb={2}
                  alignItems="center"
                >
                  <Typography
                    color={styles.color}
                    fontSize={isMobile ? "28px" : "36px"}
                    fontWeight={isMobile ? 600 : 500}
                  >
                    {currentMeal?.title}
                  </Typography>
                  <Box display={"flex"} gap={"10px"}>
                    <Box
                      textAlign="center"
                      alignItems={"center"}
                      justifyContent={"left"}
                      display="flex"
                      gap={2}
                      sx={{
                        background:
                          currentTheme === "light"
                            ? "#FEECE6"
                            : styles.modBackgroundBlocks,
                        padding: "8px 16px"
                      }}
                      borderRadius="8px"
                    >
                      <Box>
                        <Typography
                          fontSize="12px"
                          fontWeight={400}
                          color={styles.inputLabelColor}
                        >
                          800g
                        </Typography>
                        <Typography
                          fontSize="24px"
                          fontWeight={600}
                          color="#F6541C"
                        >
                          {currentMeal?.total800Gms || 0}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      alignItems={"center"}
                      justifyContent={"left"}
                      display="flex"
                      gap={2}
                      textAlign="center"
                      sx={{
                        background:
                          currentTheme === "light"
                            ? "#D4E9FC"
                            : styles.modBackgroundBlocks,
                        padding: "8px 16px"
                      }}
                      borderRadius="8px"
                    >
                      <Box>
                        <Typography
                          fontSize="12px"
                          fontWeight={400}
                          color={styles.inputLabelColor}
                        >
                          Protein g
                        </Typography>
                        <Typography
                          fontSize="24px"
                          fontWeight={600}
                          color="#2C75C5"
                        >
                          {currentMeal?.totalProteinGms || 0}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display={isMobile ? "flex" : "block"}
                  alignItems={isMobile ? "center" : "flex-start"}
                  justifyContent={isMobile ? "space-between" : "flex-start"}
                >
                  <Box display="flex" gap="10px" alignItems="center">
                    {currentMeal?.adminUserData?.[0]?.profilePic ||
                    currentMeal?.userData?.[0]?.profilePic ? (
                      <Box
                        component="img"
                        height={40}
                        borderRadius="50%"
                        width={40}
                        src={mealUserImageSrc}
                        alt="avatar"
                      />
                    ) : (
                      <Avatar sx={{ width: 40, height: 40 }}>
                        {getInitials(
                          currentMeal?.adminUserData?.[0]?.name ||
                            currentMeal?.userData?.[0]?.fullName
                        )}
                      </Avatar>
                    )}{" "}
                    <Typography
                      color={styles.color}
                      fontSize="14px"
                      fontWeight={400}
                    >
                      {currentMeal?.adminUserData?.[0]?.name ||
                        currentMeal?.userData?.[0]?.fullName}
                    </Typography>
                  </Box>

                  <Box sx={{ mt: { md: 3 } }} display="flex" gap="10px">
                    <IconButton
                      onClick={async () => {
                        await likeMeal(currentMeal?._id);
                        await dispatch(
                          getCurrentDateMeal({
                            currentDate: dayjs().format("YYYY-MM-DD")
                          })
                        );
                      }}
                      style={{
                        background: isLiked ? "#E94545" : "transparent",
                        border: isLiked ? "none" : `1px solid ${styles.color}`
                      }}
                      sx={{
                        padding: 2,
                        height: "32px",
                        borderRadius: "4px"
                      }}
                    >
                      {isLiked ? (
                        <Favorite
                          sx={{
                            height: "20px",
                            width: "20px",
                            fill: "#FCFCFC"
                          }}
                        />
                      ) : (
                        <FavoriteBorder
                          sx={{
                            height: "20px",
                            width: "20px",
                            fill: styles.color
                          }}
                        />
                      )}
                      <Typography
                        color={isLiked ? "#FCFCFC" : styles.color}
                        ml={1}
                      >
                        {currentMeal?.likeCount || 0}
                      </Typography>
                    </IconButton>
                    <IconButton
                      onClick={async () => {
                        await favoriteMeal(currentMeal?._id);
                        await dispatch(
                          getCurrentDateMeal({
                            currentDate: dayjs().format("YYYY-MM-DD")
                          })
                        );
                      }}
                      style={{
                        background: isFavorite ? "#f1b44d" : "transparent",
                        border: isFavorite
                          ? "none"
                          : `1px solid ${styles.color}`
                      }}
                      sx={{
                        height: "32px",
                        borderRadius: "4px"
                      }}
                    >
                      {isFavorite ? (
                        <TurnedIn
                          sx={{
                            height: "20px",
                            width: "20px",
                            fill: "#FCFCFC"
                          }}
                        />
                      ) : (
                        <TurnedInNot
                          sx={{
                            height: "20px",
                            width: "20px",
                            fill: styles.color
                          }}
                        />
                      )}
                    </IconButton>
                  </Box>
                </Box>

                {/* <Box
                  mt={3}
                  height={64}
                  borderRadius={2}
                  display="flex"
                  gap="10px"
                >
                  <Box
                    width={"100%"}
                    alignItems={"center"}
                    justifyContent={"left"}
                    display="flex"
                    gap={2}
                    sx={{
                      background:
                        currentTheme === "light"
                          ? "#FEECE6"
                          : styles.modBackgroundBlocks,
                      padding: "8px 16px"
                    }}
                    borderRadius="8px"
                  >
                    <AppleIcon />
                    <Box>
                      <Typography
                        fontSize="12px"
                        fontWeight={400}
                        color={styles.inputLabelColor}
                      >
                        800g
                      </Typography>
                      <Typography
                        fontSize="24px"
                        fontWeight={600}
                        color="#F6541C"
                      >
                        {currentMeal?.total800Gms || 0}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    width={"100%"}
                    alignItems={"center"}
                    justifyContent={"left"}
                    display="flex"
                    gap={2}
                    sx={{
                      background:
                        currentTheme === "light"
                          ? "#D4E9FC"
                          : styles.modBackgroundBlocks,
                      padding: "8px 16px"
                    }}
                    borderRadius="8px"
                  >
                    <ProteinIcon />
                    <Box>
                      <Typography
                        fontSize="12px"
                        fontWeight={400}
                        color={styles.inputLabelColor}
                      >
                        Protein
                      </Typography>
                      <Typography
                        fontSize="24px"
                        fontWeight={600}
                        color="#2C75C5"
                      >
                        {currentMeal?.totalProteinGms || 0}g
                      </Typography>
                    </Box>
                  </Box>
                </Box> */}

                <Box
                  width={"100%"}
                  sx={{
                    background: styles.modBackgroundBlocks,
                    padding: "12px 16px",
                    color: "#9CA3AF",
                    mt: 3,
                    borderRadius: "8px"
                  }}
                >
                  <Typography
                    color={styles.color}
                    fontSize="16px"
                    fontWeight={600}
                  >
                    Ingredients
                  </Typography>
                  <ul
                    style={{
                      paddingLeft: "18px",
                      margin: 0,
                      marginTop: 15,
                      color: styles.inputLabelColor
                    }}
                  >
                    {currentMeal?.fruitsVeggies?.map((item) => (
                      <li key={item._id}>
                        <Typography variant="body1">
                          {item.quantity
                            ? `${item.quantity} ${item.unit} ${item.name}`
                            : item.name}
                        </Typography>
                      </li>
                    ))}
                    {currentMeal?.otherIngredients?.map((item) => (
                      <li key={item._id}>
                        <Typography variant="body1">
                          {item.quantity
                            ? `${item.quantity} ${item.unit} ${item.name}`
                            : item.name}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Box>

                {currentMeal?.generalText && (
                  <Box
                    width={"100%"}
                    sx={{
                      background: styles.modBackgroundBlocks,
                      padding: "12px 16px",
                      mt: 3,
                      borderRadius: "8px"
                    }}
                  >
                    <Typography
                      color={styles.color}
                      fontSize="16px"
                      fontWeight={600}
                    >
                      Details
                    </Typography>
                    <Typography
                      mt={2}
                      variant="body1"
                      color={styles.inputLabelColor}
                    >
                      {convertTextToLink(currentMeal?.generalText)}
                    </Typography>
                  </Box>
                )}

                {/* For Mobile, Submit MOD Button at the end */}
                {isMobile && (
                  <Box display="flex" justifyContent="end">
                    <Button
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setIsAddingMeal(!isAddingMeal);
                      }}
                      sx={{
                        textTransform: "none",
                        background: "#368AD0",
                        color: "#fcfcfc",
                        height: "40px",
                        padding: "12px 24px",
                        gap: "8px",
                        borderRadius: "4px",
                        mt: 3 // Make sure it has some margin on top
                      }}
                    >
                      <Add /> Submit MOD
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <Box mt={5}>No Meal Scheduled for today</Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Mod;
