import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { registerUser } from "../../features/auth/authSlice";
import { selectAuthLoading } from "../../features/auth/authSelectors";
import {
  CheckBoxOutlineBlank,
  CheckBox as CheckBoxIcon,
  VisibilityOff,
  Visibility,
  ChevronLeft
} from "@mui/icons-material";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  CircularProgress,
  Snackbar,
  Avatar,
  Box,
  FormControl,
  InputLabel,
  styled,
  Select,
  MenuItem,
  Alert,
  useMediaQuery,
  IconButton,
  InputAdornment
} from "@mui/material";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ReactComponent as CameraIcon } from "../../assets/camera.svg";
import { getUserType } from "../../api/userApi";
import { uploadFile } from "../../utils/uploadFile";
import { getUniqueSuffix } from "../../utils/getUniqueSuffix";
import useThemeColor from "../../utils/useTheme";
import { useTheme } from "@emotion/react";

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  margin: theme.spacing(4, 0)
}));

const StyledInputLabel = styled("label")(({ theme }) => ({
  position: "absolute",
  top: "-18px",
  color: "#D1D5DB",
  fontWeight: "bold",
  fontSize: "14px",
  pointerEvents: "none"
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  input: {
    color: "#FCFCFC"
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: "#9CA3AF",
      borderColor: "#333333"
    },
    "&:hover fieldset": {
      borderColor: "#FCFCFC"
    },
    "&.Mui-focused fieldset": {
      color: "#9CA3AF",
      borderColor: "#FCFCFC"
    }
  }
}));

const Register = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    displayName: "",
    email: "",
    password: "",
    profilePic: null,
    bio: "",
    url: "",
    referralCode: "",
    isJoiningThroughSource: false,
    userType: "Individual",
    referenceId: ""
  });
  const [availableOptions, setAvailableOptions] = useState({});
  const [formErrors, setFormErrors] = useState({
    fullName: false,
    displayName: false,
    email: false,
    password: false,
    bio: false,
    url: false,
    referralCode: false
  });
  const matches = useMediaQuery("(max-width:590px)");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };
  const fileInputRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const loading = useSelector(selectAuthLoading);
  const navigate = useNavigate();

  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);
  const validateUrl = (url) =>
    /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/.test(
      url
    );
  const validatePassword = (password) =>
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])[A-Za-z\d#?!@$%^&*-]{8,}$/.test(
      password
    );

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value
    });

    if (name === "password") {
      setFormErrors({ ...formErrors, password: !validatePassword(value) });
    } else if (name === "bio") {
      setFormErrors({
        ...formErrors,
        bio: !(value.length < 51)
      });
    } else {
      setFormErrors({ ...formErrors, [name]: value.trim() === "" });
    }
  };

  const handleBlurValidation = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setFormErrors({ ...formErrors, email: !validateEmail(value) });
    }
    if (name === "url") {
      setFormErrors({ ...formErrors, url: !validateUrl(value) });
    }
    if (name === "referralCode" && !formData.referralCode) {
      setFormErrors({ ...formErrors, referralCode: true });
    }
  };

  const handleEditClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    if (formData.isJoiningThroughSource) {
      setFormData((prevState) => ({
        ...prevState,
        referenceId: ""
      }));
    }
  }, [formData.userType, formData.isJoiningThroughSource]);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 30 * 1024 * 1024) {
      setSnackbarMessage("File size should be less than 30MB.");
      setOpenSnackbar(true);
      return;
    }
    if (file) {
      setFormData((prevState) => ({
        ...prevState,
        profilePic: file
      }));
    }
  };

  const getUserTypes = async () => {
    try {
      const data = await getUserType();
      return data;
    } catch (error) {
      console.error(
        "Get user types failed:",
        error.response ? error.response.data : error.message
      );
      throw error.response ? error.response.data : error;
    }
  };
  const styles = useThemeColor();
  useEffect(() => {
    getUserTypes()
      .then((data) => {
        if (data.usersTypeList) {
          setAvailableOptions(data.usersTypeList);
        }
      })
      .catch(() => setAvailableOptions([]));
  }, []);
  const handleRegister = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    if (
      formData.userType !== "Individual" &&
      !formData.referenceId &&
      formData.isJoiningThroughSource
    ) {
      setSnackbarMessage("Please select the source of joining.");
      setOpenSnackbar(true);
      setSubmitLoading(false);
      return;
    }
    if (
      formData.userType !== "Individual" &&
      !formData.referralCode &&
      formData.isJoiningThroughSource
    ) {
      setSnackbarMessage(
        `Please enter code given by your ${formData.userType}`
      );
      setOpenSnackbar(true);
      setSubmitLoading(false);
      return;
    }
    if (Object.values(formErrors).some((error) => error)) {
      setSnackbarMessage("Please correct the form errors.");
      setOpenSnackbar(true);
      setSubmitLoading(false);
      return;
    }
    if (!formData.isJoiningThroughSource) {
      formData.userType = "Individual";
      formData.referenceId = "";
      formData.referralCode = "";
    }
    delete formData.isJoiningThroughSource;
    try {
      const uniqueFileName = getUniqueSuffix(formData.profilePic?.name);

      const { payload } = await dispatch(
        registerUser({
          ...formData,
          ...(formData.profilePic && {
            profilePic: uniqueFileName
          })
        })
      );
      if (formData.profilePic)
        await uploadFile(
          formData.profilePic,
          uniqueFileName,
          process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
        );
      if (!payload.error) navigate("/", { state: { startFreeTrial: true } });
      else {
        setSnackbarMessage(payload.error || "Registration failed.");
        setOpenSnackbar(true);
      }
      setSubmitLoading(false);
    } catch (error) {
      setSnackbarMessage("Registration failed.");
      setOpenSnackbar(true);
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    const scrollableElement = document.documentElement; // or document.body
    scrollableElement.scrollTo(0, 0);
  }, []);

  return (
    <Box
      color={"#FCFCFC"}
      backgroundColor={"#1C1C1C"}
      display={"flex"}
      width="100%"
      overflow="auto"
      height="100dvh"
      sx={{
        flexDirection: { xs: "column", md: "row" }
      }}
    >
      <Box
        sx={{
          left: 0,
          top: 0,
          width: { xs: 0, md: "50%" },
          display: { xs: "none", md: "block" },
          objectFit: "cover"
        }}
      >
        <Box
          component="img"
          src="/images/base.png"
          alt="base"
          sx={{
            width: "100%",
            height: "100dvh",
            objectFit: "cover"
          }}
        />
      </Box>
      <Box
        sx={{
          left: 0,
          top: 0,
          width: { md: "0" },
          objectFit: "cover",
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          margin: "20px 0 0 0"
        }}
      >
        <Box
          component="img"
          src="/images/threepillars.png"
          alt="base"
          sx={{
            width: { xs: "60px", md: "100px" },
            height: { xs: "60px", md: "100px" },
            objectFit: "cover"
          }}
        />
      </Box>
      <Box
        flexGrow={1}
        paddingY={3}
        paddingLeft={10}
        sx={{
          paddingTop: { xs: 2, md: 8, lg: 10 },
          paddingRight: { xs: 2, md: 13, lg: 20 },
          paddingBottom: { xs: 8, md: 2 },
          paddingLeft: { xs: 2, md: 10 },
          overflowY: { md: "auto" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          ...(!isMobile && { width: "30%" })
        }}
      >
        {!matches ? (
          <Box display="flex" gap="10px" mb={3} alignItems={"center"}>
            <IconButton onClick={() => navigate("/login")}>
              <ChevronLeft
                sx={{ height: "35px", width: "35px", fill: styles.color }}
              />
            </IconButton>
            <Typography
              sx={{
                ...(matches && { display: "flex", justifyContent: "center" })
              }}
              fontSize="28px"
              fontWeight="bold"
            >
              Create Account
            </Typography>
          </Box>
        ) : (
          <Typography
            sx={{
              ...(matches && { display: "flex", justifyContent: "center" })
            }}
            fontSize="28px"
            fontWeight="bold"
          >
            Create Account
          </Typography>
        )}
        <Snackbar
          open={openSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={3000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={
              snackbarMessage?.includes("success") ||
              snackbarMessage?.includes("Success")
                ? "success"
                : "error"
            }
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Box
          component={"form"}
          sx={{
            ...(matches && { marginBottom: { xs: 9 } })
          }}
          onSubmit={handleRegister}
        >
          <input
            accept="image/png, image/gif, image/jpeg"
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="profile-pic-upload"
          />
          <Box
            sx={{
              ...(matches && { justifyContent: "center" })
            }}
            display="flex"
            gap="50px"
            alignItems="center"
          >
            {formData.profilePic ? (
              <Box sx={{ position: "relative", display: "inline-block" }}>
                <label htmlFor="profile-pic-upload">
                  <Avatar
                    alt="Profile Pic"
                    src={URL.createObjectURL(formData.profilePic)}
                    sx={{
                      width: 130,
                      height: 130,
                      marginTop: 2
                    }}
                  />
                  <IconButton
                    onClick={handleEditClick}
                    sx={{
                      position: "absolute",
                      bottom: 10, // Adjust this value to move the icon slightly outside the avatar if desired
                      right: 15, // Adjust this value to move the icon slightly outside the avatar if desired
                      backgroundColor: "#368AD0",
                      borderRadius: "50%",
                      height: 15,
                      width: 15,
                      fontSize: 24, // Adjust the size as needed
                      padding: "2px",
                      svg: {
                        path: {
                          fill: "#fff"
                        }
                      }
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </label>
              </Box>
            ) : (
              <label htmlFor="profile-pic-upload">
                <Button
                  variant="contained"
                  component="span"
                  color="default"
                  style={{
                    borderRadius: "48%",
                    backgroundColor: "#ccc",
                    width: 130,
                    height: 130,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 16,
                    cursor: "pointer"
                  }}
                >
                  <CameraIcon style={{ width: 24, height: 24 }} />
                </Button>
              </label>
            )}
          </Box>
          <StyledTextFieldContainer style={{ marginTop: "40px" }}>
            <StyledInputLabel htmlFor="fullName">Name *</StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              name="fullName"
              placeholder="Enter your Full name"
              value={formData.fullName}
              onChange={handleInputChange}
              error={formErrors.fullName}
              helperText={formErrors.fullName && "Name is required"}
              margin="normal"
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="displayName">
              Username (Displayed in app) *
            </StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              name="displayName"
              sx={{ marginTop: "20px" }}
              placeholder="Enter your Username"
              value={formData.displayName}
              onChange={handleInputChange}
              error={formErrors.displayName}
              helperText={formErrors.displayName && "Username is required"}
              margin="normal"
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="bio">
              Short Bio (50 character max)
            </StyledInputLabel>
            <StyledTextField
              sx={{
                "& .MuiInputBase-root": {
                  color: "#FCFCFC"
                }
              }}
              fullWidth
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              variant="outlined"
              name="bio"
              placeholder="Enter your bio (optional)"
              value={formData.bio}
              multiline
              rows={4}
              onChange={handleInputChange}
              margin="normal"
              error={formErrors.bio}
              helperText={
                formErrors.bio && "Bio should be less than 50 characters"
              }
            />
            <Typography
              variant="caption"
              sx={{
                color: formData.bio.length > 50 ? "red" : "#D1D5DB",
                position: "absolute",
                fontSize: "15px",
                right: 10,
                bottom: 10
              }}
            >
              {formData.bio.length}/50
            </Typography>
          </StyledTextFieldContainer>

          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="email">Email Address *</StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              name="email"
              type="email"
              placeholder="Enter your email address"
              value={formData.email}
              onChange={handleInputChange}
              onBlur={handleBlurValidation}
              error={formErrors.email}
              helperText={formErrors.email && "Enter a valid email address"}
              margin="normal"
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="password">Password *</StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseUpPassword}
                      sx={{ color: "#FCFCFC" }}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              name="password"
              placeholder="Enter Password"
              value={formData.password}
              onChange={handleInputChange}
              error={formErrors.password}
              helperText={
                formErrors.password &&
                "Password must be 8 characters long: Contains 1 upper, 1 lower case, 1 symbol and 1 digit"
              }
              margin="normal"
            />
          </StyledTextFieldContainer>

          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="url">
              URL (link to their website, social media, etc.)
            </StyledInputLabel>
            <StyledTextField
              fullWidth
              sx={{ marginTop: "20px" }}
              variant="outlined"
              name="url"
              placeholder="Enter URL (optional)"
              value={formData.url}
              onChange={handleInputChange}
              // onBlur={handleBlurValidation}
              // error={formErrors.url}
              // helperText={formErrors.url && "Enter a valid URL"}
              margin="normal"
            />
          </StyledTextFieldContainer>
          <FormControlLabel
            control={
              <Checkbox
                name="isJoiningThroughSource"
                checked={formData.isJoiningThroughSource}
                onChange={handleInputChange}
                icon={
                  <CheckBoxOutlineBlank
                    sx={{ color: "#D1D5DB" }} // Gray color when unchecked
                  />
                }
                checkedIcon={
                  <CheckBoxIcon
                    sx={{
                      color: "#FCFCFC",
                      borderRadius: "4px"
                    }}
                  />
                }
                sx={{
                  "&.Mui-checked": {
                    border: "none",
                    color: "#368AD0" // Green border when checked
                  }
                }}
              />
            }
            label="Are you joining through Coach / Gym / Company"
            sx={{
              color: "#D1D5DB",
              fontWeight: "bold",
              fontSize: "14px",
              marginBottom: "16px"
            }}
          />

          {formData.isJoiningThroughSource &&
          Object.values(availableOptions)?.length > 0 ? (
            <>
              {" "}
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginTop: 30 }}
              >
                <InputLabel
                  id="source-of-joining-label"
                  sx={{ color: "#FCFCFC" }}
                >
                  Source of joining
                </InputLabel>
                <Select
                  labelId="source-of-joining-label"
                  id="source-of-joining"
                  name="userType"
                  value={formData.userType}
                  onChange={handleInputChange}
                  label="Source of joining"
                  sx={{
                    // width: "100%",
                    color: "#FCFCFC", // White font for selected value
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#333" // Border color
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FCFCFC" // Border color when focused
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FCFCFC" // Border color on hover
                    }
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: "#1C1C1C", // Black background for the dropdown
                        color: "#FCFCFC", // White font color for the menu items
                        "& .MuiMenuItem-root": {
                          padding: "10px" // Adjust padding inside menu items
                        },
                        "& .Mui-selected": {
                          backgroundColor: "#333" // Custom color for selected item
                        },
                        "& .MuiMenuItem-root:hover": {
                          backgroundColor: "#444" // Darker hover background
                        }
                      }
                    }
                  }}
                >
                  <MenuItem value="Coach">Coach</MenuItem>
                  <MenuItem value="Gym">Gym</MenuItem>
                  <MenuItem value="Company">Company</MenuItem>
                </Select>
              </FormControl>
              {formData.userType !== "Individual" && (
                <>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    style={{ marginTop: 30 }}
                  >
                    <InputLabel
                      id="source-of-joining-name"
                      sx={{ color: "#FCFCFC" }}
                    >
                      {formData.userType} name
                    </InputLabel>
                    <Select
                      labelId="source-of-joining-name"
                      id="source-of-joining"
                      name="referenceId"
                      value={formData.referenceId}
                      onChange={handleInputChange}
                      label="Source of joining"
                      sx={{
                        // width: "100%",
                        color: "#FCFCFC",
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#333"
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#FCFCFC"
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#FCFCFC"
                        },
                        // Limit selected value width with ellipsis
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            backgroundColor: "#1C1C1C",
                            color: "#FCFCFC",
                            // width: "100%",
                            // maxWidth: "300px", // Set max width for menu items
                            "& .MuiMenuItem-root": {
                              padding: "10px"
                            },
                            "& .Mui-selected": {
                              backgroundColor: "#333"
                            },
                            "& .MuiMenuItem-root:hover": {
                              backgroundColor: "#444"
                            }
                          }
                        }
                      }}
                    >
                      {availableOptions?.[formData?.userType.toLowerCase()]
                        ?.length ? (
                        availableOptions?.[
                          formData?.userType.toLowerCase()
                        ]?.map((option) => {
                          return (
                            <MenuItem
                              value={option._id}
                              style={{
                                // maxWidth: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap"
                              }}
                            >
                              {
                                option[
                                  formData?.userType?.toLowerCase() + "Name"
                                ]
                              }
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem
                          disabled
                          style={{
                            // maxWidth: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                          }}
                        >
                          No options available
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  <StyledTextFieldContainer sx={{ mt: 5 }}>
                    <StyledInputLabel htmlFor="referralCode">
                      {formData.userType} Code*
                    </StyledInputLabel>
                    <StyledTextField
                      fullWidth
                      sx={{ marginTop: "20px" }}
                      variant="outlined"
                      name="referralCode"
                      placeholder={`Enter ${formData.userType} code`}
                      value={formData.referralCode}
                      onChange={handleInputChange}
                      // onBlur={handleBlurValidation}
                      error={formErrors.referralCode}
                      helperText={
                        formErrors.referralCode &&
                        `Enter code given by your ${formData.userType}`
                      }
                      margin="normal"
                    />
                  </StyledTextFieldContainer>
                </>
              )}
            </>
          ) : (
            <></>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              loading ||
              Object.values(formErrors).some((error) => error) ||
              !formData.fullName ||
              !formData.displayName ||
              !formData.email ||
              !formData.password
            }
            sx={{
              marginTop: 3.1,
              backgroundColor: "#368AD0",
              cursor: "pointer",
              color: "#fff",
              width: "100%",
              height: "42px",
              fontWeight: "bold",
              textTransform: "none",
              ":disabled": {
                backgroundColor: "#A7C8E9",
                color: "#64AEDD"
              }
            }}
          >
            {submitLoading ? <CircularProgress size={24} /> : "Sign up"}
          </Button>
          <Box
            mt={6}
            display="flex"
            gap="20px"
            textAlign="center"
            justifyContent="center"
            alignItems="center"
          >
            <Typography>Already Member?</Typography>
            <Link
              to="/login"
              variant="outlined"
              style={{
                color: "#368AD0",
                textDecoration: "none"
              }}
            >
              Sign In
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Register;
