import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
// Function to format date
export function formatDate(date, isYearNeeded = true) {
  if (date) {
    // when isYearNeeded is false, we are passing formatted date otherwise we are passing date iso string so that needs to pass in utc
    const inputDate = !isYearNeeded ? dayjs(date) : dayjs.utc(date);
    const today = dayjs();
    if (inputDate.isSame(today, "day")) {
      return "Today";
    }
    if (inputDate.isSame(today.subtract(1, "day"), "day")) {
      return "Yesterday";
    }

    return inputDate.format(isYearNeeded ? "D MMM YYYY" : "D MMM");
  }
}
