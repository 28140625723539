import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import CompleteVerification from "./components/Auth/CompleteVerification";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ChangePassword from "./components/Auth/ChangePassword";
import PrivateRoute from "./components/Auth/PrivateRoute";
import NotFound from "./components/NotFound";
import EditProfile from "./components/Modals/EditProfile";
import { Box, CssBaseline } from "@mui/material";
import Mod from "./components/Tabs/Mod";
import Profile from "./components/Tabs/Profile";
import AdminDashboard from "./pages/Dashboard";
import UpdatePassword from "./components/Modals/UpdatePassword";
import Meals from "./components/Tabs/Meals";
import Programs from "./components/Tabs/Programs";
import useThemeColor from "./utils/useTheme";
import Diary from "./components/Tabs/Diary";
import VerifyIdentity from "./components/Auth/VerifyIdentity";
import CardLists from "./components/Modals/CardLists";
import ManageBilling from "./components/Modals/ManageBilling";
import PointsStats from "./components/Modals/PointStats";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollableElement = document.getElementById("dashboard-main"); // or document.body
    scrollableElement.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const DashboardLayout = () => {
  const [activeLevelForMobile, setActiveLevelForMobile] = useState(false);
  const [activeDiaryLevel, setActiveDiaryLevel] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [openSubscriptionModal, setOpenSubscriptionModal] =
    React.useState(false);
  const [openFreeTrialModal, setOpenFreeTrialModal] = React.useState(false);
  const styles = useThemeColor();
  return (
    <Box display="flex">
      <CssBaseline />
      <AdminDashboard
        openFreeTrialModal={openFreeTrialModal}
        setOpenFreeTrialModal={setOpenFreeTrialModal}
        openSubscriptionModal={openSubscriptionModal}
        setOpenSubscriptionModal={setOpenSubscriptionModal}
        open={open}
        setOpen={setOpen}
        activeLevelForMobile={activeLevelForMobile}
      />
      <Box
        component="main"
        id="dashboard-main"
        sx={{
          flexGrow: 1,
          color: styles.color,
          background: styles.background,
          padding: {
            xs: "0 0 115px 0",
            md: 0
          },
          height: "100dvh",
          overflow: "auto"
        }}
      >
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <Mod setOpenSubscriptionModal={setOpenSubscriptionModal} />
            }
          />
          <Route
            path="/diary"
            element={
              <Diary
                setOpenSubscriptionModal={setOpenSubscriptionModal}
                activeLevelForMobile={activeDiaryLevel}
                setActiveLevelForMobile={setActiveDiaryLevel}
              />
            }
          />
          <Route
            path="/programs"
            element={
              <Programs
                setOpenSubscriptionModal={setOpenSubscriptionModal}
                open={open}
                activeLevelForMobile={activeLevelForMobile}
                setActiveLevelForMobile={setActiveLevelForMobile}
              />
            }
          />
          <Route
            path="/meals"
            element={
              <Meals setOpenSubscriptionModal={setOpenSubscriptionModal} />
            }
          />
          <Route
            path="/profile"
            element={
              <Profile
                openSubscriptionModal={openSubscriptionModal}
                setOpenSubscriptionModal={setOpenSubscriptionModal}
                setOpenFreeTrialModal={setOpenFreeTrialModal}
              />
            }
          />
          <Route
            path="/edit-profile"
            element={
              <EditProfile
                setOpenSubscriptionModal={setOpenSubscriptionModal}
              />
            }
          />
          <Route
            path="/point-stats"
            element={
              <PointsStats
                setOpenSubscriptionModal={setOpenSubscriptionModal}
              />
            }
          />
          <Route
            path="/update-password"
            element={
              <UpdatePassword
                setOpenSubscriptionModal={setOpenSubscriptionModal}
              />
            }
          />
          <Route
            path="/manage-billing"
            element={
              <ManageBilling
              // setOpenSubscriptionModal={setOpenSubscriptionModal}
              />
            }
          />
        </Routes>
      </Box>
    </Box>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verify-identity" element={<VerifyIdentity />} />
        <Route
          path="/complete-registeration"
          element={<CompleteVerification />}
        />

        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ChangePassword />} />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <DashboardLayout />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
