import React, { useEffect, useMemo, useState } from "react";
import { ChevronLeft } from "@mui/icons-material";
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  Box,
  styled,
  Alert,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  List,
  ListItem
} from "@mui/material";
import CustomRadio from "../Extra/CustomRadio";
import { addIngredients, updateIngredients } from "../../api/diaryApi"; // Update to your actual API import
import dayjs from "dayjs";
// import { options800gms, optionsProteins } from "../../utils/mockWeightData";
import {
  getCurrentUserDiaries,
  getCurrentUserDiaryLevels
} from "../../features/diary/diarySlice";
import { useDispatch, useSelector } from "react-redux";
import useThemeColor from "../../utils/useTheme";
import {
  selectAllOptionsProtein,
  selectAllOptions800g
} from "../../features/diary/diarySelectors";
import { selectCurrentUser } from "../../features/auth/authSelectors";
import { fetchCurrentUser } from "../../features/auth/authSlice";

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  margin: theme.spacing(4, 0)
}));

const StyledInputLabel = styled("label")(({ styles }) => ({
  position: "absolute",
  top: "-18px",
  color: styles.inputLabelColor,
  fontSize: "14px",
  pointerEvents: "none"
}));

const StyledTextField = styled(TextField)(({ styles }) => ({
  input: {
    color: styles.color,
    "-webkit-text-fill-color": `${styles.color} !important`
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: styles.lightGreyColor
    },
    "&:hover fieldset": {
      borderColor: styles.color
    },
    "&.Mui-focused fieldset": {
      borderColor: styles.color
    },
    "&.Mui-disabled fieldset": {
      borderColor: `${styles.lightGreyColor} !important`
    }
  }
}));

const AddWeightForm = ({
  selectedLevel,
  diaryId,
  activeDay,
  setIsAddingWeight,
  isAddingWeight,
  levelId,
  editData,
  setIsRefreshNeeded
}) => {
  const [isDiaryRefreshNeeded, setIsDiaryRefreshNeeded] = useState(false);
  const dispatch = useDispatch();
  const options800gms = useSelector(selectAllOptions800g);
  const optionsProteins = useSelector(selectAllOptionsProtein);
  const userData = useSelector(selectCurrentUser);
  const options = isAddingWeight?.includes("800")
    ? options800gms
    : optionsProteins;
  // useMemo(() => {
  //   const baseOptions = isAddingWeight?.includes("800")
  //     ? options800gms
  //     : optionsProteins;

  //   // if (
  //   //   editData?.name &&
  //   //   !baseOptions.some((opt) => opt.value === editData.name)
  //   // ) {
  //   //   return [...baseOptions, { value: editData.name, label: editData.name }];
  //   // }

  //   return baseOptions;
  // }, [isAddingWeight, isDiaryRefreshNeeded, editData]);

  useEffect(() => {
    if (isDiaryRefreshNeeded) {
      dispatch(getCurrentUserDiaryLevels(dayjs().format("YYYY-MM-DD")));
      setIsDiaryRefreshNeeded(false);
    }
  }, [isDiaryRefreshNeeded]);
  const [formData, setFormData] = useState({
    quantity: editData?.quantity || "",
    unit: editData?.unit || "grams",
    [isAddingWeight]: editData?.name
      ? options?.map((item) => item.value)?.includes(editData?.name)
        ? editData?.name
        : "others"
      : "",
    customFoodType: editData?.name
      ? options?.map((item) => item.value)?.includes(editData?.name)
        ? ""
        : editData?.name
      : ""
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const loading = false; // Replace with actual loading state if needed
  const styles = useThemeColor();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData?.[isAddingWeight] || !formData.quantity) {
      const errors = [];
      if (!formData?.[isAddingWeight])
        errors.push("Please select a food type.");
      if (!formData.quantity) errors.push("Please enter a quantity.");
      setSnackbarMessage(errors.join(" "));
      setOpenSnackbar(true);
      return;
    }

    try {
      const currentTime = dayjs().format("hh:mm A");
      if (editData?._id) {
        await updateIngredients({
          diaryId,
          levelId,
          ingredientId: editData?._id,
          currentDayDate: activeDay,
          [isAddingWeight]: {
            name:
              formData?.[isAddingWeight] === "others"
                ? formData.customFoodType
                  ? formData.customFoodType
                  : "Other"
                : formData?.[isAddingWeight],
            quantity: formData?.quantity,
            time: currentTime,
            unit: formData?.unit
          }
        });
      } else {
        await addIngredients({
          levelId,
          currentDayDate: activeDay,
          [isAddingWeight]: {
            name:
              formData.customFoodType && formData?.[isAddingWeight] === "others"
                ? formData.customFoodType
                : formData?.[isAddingWeight],
            quantity: formData?.quantity,
            time: currentTime,
            unit: formData?.unit
          }
        });
      }
      setSnackbarMessage("Entry logged successfully.");
      setOpenSnackbar(true);
      setFormData({ quantity: "", [isAddingWeight]: "", customFoodType: "" });
      setIsAddingWeight("");
      setIsRefreshNeeded(true);
      dispatch(getCurrentUserDiaries({ levelId }));
      dispatch(getCurrentUserDiaryLevels(dayjs().format("YYYY-MM-DD")));
      if (!userData?.isSubscriptionActive) {
        // dispatch(getCurrentUserDiaryLevels(dayjs().format("YYYY-MM-DD")));
        dispatch(fetchCurrentUser(dayjs().format("YYYY-MM-DD")));
      }
      localStorage.setItem(
        "lastDiaryVisitedLevel",
        JSON.stringify({ level: selectedLevel?.levelName, activeDay })
      );
    } catch (error) {
      console.error(error);
      setSnackbarMessage("Failed to log entry.");
      setOpenSnackbar(true);
    }
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
    const scrollableElement = document.getElementById("dashboard-main"); // or document.body
    scrollableElement.scrollTo(0, 0);
  }, []);

  return (
    <Box
      color={styles.color}
      backgroundColor={styles.background}
      display="flex"
      width="100%"
      overflow="auto"
      flexDirection="column"
    >
      <Box display="flex" alignItems="center" gap={3} p="20px 0 0 10px">
        <IconButton onClick={() => setIsAddingWeight(false)}>
          <ChevronLeft
            sx={{ height: "35px", width: "35px", fill: styles.color }}
          />
        </IconButton>
        <Typography textTransform="capitalize" fontSize="24px">
          {isAddingWeight?.includes("800g") ? "#800gChallenge" : isAddingWeight}{" "}
          Log
        </Typography>
      </Box>
      <Box
        flexGrow={1}
        py={3}
        px={{ xs: 2, md: 10 }}
        sx={{
          width: { xs: "100%", md: "100%", lg: "65%" },
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Box component="form" onSubmit={handleSubmit}>
          <Typography
            variant="body"
            fontSize="13px"
            sx={{ color: styles.inputLabelColor }}
            fontWeight={500}
          >
            Select one or create a custom entry
          </Typography>
          <Box display="flex" flexWrap="wrap">
            {options.map((option) => (
              <Box key={option.value} width="50%" p={0.5}>
                <CustomRadio
                  styles={styles}
                  value={option.value}
                  setIsDiaryRefreshNeeded={setIsDiaryRefreshNeeded}
                  label={option.label}
                  type={option.type}
                  id={option.id}
                  checked={formData?.[isAddingWeight] === option.value}
                  onChange={() =>
                    setFormData({ ...formData, [isAddingWeight]: option.value })
                  }
                />
              </Box>
            ))}
          </Box>
          {/* Show additional input field if "Others" is selected */}
          {formData?.[isAddingWeight] === "others" && (
            <StyledTextFieldContainer>
              <StyledInputLabel styles={styles} htmlFor="customFoodType">
                Custom (e.g., mango)
              </StyledInputLabel>
              <StyledTextField
                type="text"
                styles={styles}
                name="customFoodType"
                placeholder="Enter custom food"
                value={formData.customFoodType}
                onChange={handleInputChange}
                sx={{ width: "100%", mt: 2 }}
              />
            </StyledTextFieldContainer>
          )}
          <StyledTextFieldContainer
            style={{ display: "flex", gap: "20px", marginTop: "40px" }}
          >
            <StyledInputLabel
              styles={styles}
              htmlFor="quantity"
              sx={
                isAddingWeight.includes("protein") && {
                  fontSize: "16px"
                }
              }
            >
              {isAddingWeight.includes("protein")
                ? "Enter protein grams consumed"
                : "Enter quantity"}
            </StyledInputLabel>
            <StyledTextField
              styles={styles}
              type="number"
              min={0}
              name="quantity"
              inputProps={{}}
              margin="normal"
              htmlInput={{ min: 0 }}
              placeholder="No. (Optional)"
              value={formData.quantity}
              onChange={(e) => {
                const value = e.target.value;
                // Allow only numeric values and ensure it's at least 0
                if (/^\d*$/.test(value) && Number(value) >= 0) {
                  handleInputChange(e);
                }
              }}
              sx={{ width: { xs: "100%", md: "60%" } }}
            />
            {/* <StyledTextField
              styles={styles}
              margin="normal"
              placeholder="Weight Grams"
              value="Grams"
              disabled
              sx={{ width: "30%", background: styles.lightGreyColor }}
            /> */}
            {isAddingWeight?.includes("800g") ? (
              <FormControl
                sx={{
                  width: "40%",
                  marginTop: "15px",
                  svg: {
                    fill: styles.color
                  },
                  ".MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: styles.lightGreyColor
                    },
                    "&:hover fieldset": {
                      borderColor: styles.color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: styles.color
                    }
                  }
                }}
              >
                <Select
                  labelId="unit-label"
                  name="unit"
                  value={formData.unit}
                  MenuProps={{
                    MenuListProps: {
                      sx: {
                        padding: 0,
                        ".Mui-selected": {
                          color: `${styles.background} !important`,
                          background: `${styles.color} !important`
                        }
                      }
                    }
                  }}
                  sx={{
                    color: styles.color,
                    marginTop: 0, // Remove any margin
                    paddingTop: 0 // Remove any padding
                  }}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                >
                  <MenuItem
                    sx={{
                      backgroundColor: styles.background,
                      color: styles.color,
                      paddingY: 1,
                      "&:hover": {
                        backgroundColor: styles.color,
                        color: styles.background
                      }
                    }}
                    value="grams"
                  >
                    Grams
                  </MenuItem>
                  <MenuItem
                    sx={{
                      backgroundColor: styles.background,
                      color: styles.color,
                      paddingY: 1,
                      "&:hover": {
                        backgroundColor: styles.color,
                        color: styles.background
                      }
                    }}
                    value="ounces"
                  >
                    Ounces
                  </MenuItem>
                  <MenuItem
                    sx={{
                      backgroundColor: styles.background,
                      color: styles.color,
                      paddingY: 1,
                      "&:hover": {
                        backgroundColor: styles.color,
                        color: styles.background
                      }
                    }}
                    value="cups"
                  >
                    Cups / Fists
                  </MenuItem>
                  {!isAddingWeight?.includes("800g") &&
                    [
                      { value: "palms", label: "Palms" },
                      { value: "pounds", label: "Pounds" },
                      { value: "mls", label: " MLs (millimeters)" }
                    ]?.map(({ value, label }) => (
                      <MenuItem
                        sx={{
                          backgroundColor: styles.background,
                          color: styles.color,
                          paddingY: 1,
                          "&:hover": {
                            backgroundColor: styles.color,
                            color: styles.background
                          }
                        }}
                        value={value}
                      >
                        {label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            ) : (
              <></>
            )}
          </StyledTextFieldContainer>

          {isAddingWeight?.includes("protein") ? (
            <StyledTextFieldContainer>
              <Typography
                variant="h6"
                sx={{
                  color: styles.inputLabelColor,
                  fontSize: "18px",
                  pointerEvents: "none"
                }}
                gutterBottom
              >
                Estimating Notes:
              </Typography>
              <List sx={{ listStyle: "inside" }}>
                <ListItem sx={{ display: "list-item", paddingY: 0.2 }}>
                  <Typography
                    variant="body3"
                    sx={{
                      color: styles.inputLabelColor,
                      fontSize: "15px",
                      pointerEvents: "none"
                    }}
                  >
                    Protein servings (e.g., chicken, salmon) at restaurants are
                    often about the size of your palm or a deck of cards. This
                    contains about 25g of protein.
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: "list-item", paddingY: 0.2 }}>
                  <Typography
                    variant="body3"
                    sx={{
                      color: styles.inputLabelColor,
                      fontSize: "15px",
                      pointerEvents: "none"
                    }}
                  >
                    1 oz (28g) of cooked meat contains ~7g of protein.
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: "list-item", paddingY: 0.2 }}>
                  <Typography
                    variant="body3"
                    sx={{
                      color: styles.inputLabelColor,
                      fontSize: "15px",
                      pointerEvents: "none"
                    }}
                  >
                    Weights on menus are raw portions; weight reduces by ~25%
                    when cooked (8oz burger is 6oz cooked or 42g protein).
                  </Typography>
                </ListItem>
              </List>
            </StyledTextFieldContainer>
          ) : (
            <></>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!formData.quantity || !formData?.[isAddingWeight]}
            sx={{
              marginTop: 3,
              backgroundColor: "#368AD0",
              color: "#fff",
              width: "100%",
              height: "42px",
              fontWeight: "bold",
              textTransform: "none",
              ":disabled": { backgroundColor: "#A7C8E9", color: "#64AEDD" }
            }}
          >
            {loading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </Box>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={snackbarMessage.includes("success") ? "success" : "error"}
            variant="filled"
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default AddWeightForm;
