import createApi from "../utils/axiosInstance";

export const getAllUserLevels = async (date) => {
  try {
    const api = createApi(true);

    const response = await api.get(`/user/get-user-level?date=${date}`);
    return response.data;
  } catch (error) {
    console.error(
      "Get user level failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getAllUserTrainings = async (levelId) => {
  try {
    const api = createApi(true);
    const response = await api.get(`/user/get-all-trainings/${levelId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Get user level failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const completeTraining = async (date, levelId, trainingDayId) => {
  try {
    const api = createApi(true);

    const response = await api.post(
      `/user/complete-training-day/?date=${date}`,
      {
        levelId,
        trainingDayId
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Get user level failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const purchaseTraining = async (levelId) => {
  try {
    const api = createApi(true);

    const response = await api.post(
      `/user/purchase-training?levelId=${levelId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Purchase training failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};
