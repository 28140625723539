import {
  Alert,
  Box,
  Button,
  IconButton,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ChevronLeft, ChevronRight, QuestionAnswer } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import DaySelector from "../Extra/DayChanger";
import { ReactComponent as LockIcon } from "../../assets/lock1.svg";
import {
  getCurrentUserLevels,
  getCurrentUserTrainings
} from "../../features/training/trainingSlice";
import {
  selectAllTrainingsData,
  selectLevelData
} from "../../features/training/trainingSelectors";
import CircularProgressStatic from "../Extra/ProgressTracker";
import RenderHtml from "../Extra/RenderHtml/RenderHtml";
import dayjs from "dayjs";
import { selectCurrentUser } from "../../features/auth/authSelectors";
import useThemeColor from "../../utils/useTheme";
import { purchaseTraining } from "../../api/trainingApi";

const Programs = ({
  open,
  activeLevelForMobile,
  setOpenSubscriptionModal,
  setActiveLevelForMobile
}) => {
  const [selectedLevel, setSelectedLevel] = React.useState("");
  const dispatch = useDispatch();
  const styles = useThemeColor();
  const allLevels = useSelector(selectLevelData);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const trainingData = useSelector(selectAllTrainingsData);
  const [activeDay, setActiveDay] = useState(0);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const userData = useSelector(selectCurrentUser);
  const [isAudioFinished, setIsAudioFinished] = useState(false);

  const handleLevelSelect = (level) => {
    setSelectedLevel(level);
    setActiveLevelForMobile(true);
  };
  const currentLevel = useMemo(() => {
    return allLevels?.find((item) => item?.levelName === selectedLevel);
  }, [allLevels, selectedLevel]);

  useEffect(() => {
    dispatch(getCurrentUserLevels(dayjs().format("YYYY-MM-DD")));
  }, [dispatch]);

  React.useEffect(() => {
    if (
      !(
        userData?.isAdmin === 1 ||
        ["Coach", "Company"].includes(userData?.userType)
      ) &&
      !userData?.isSubscriptionActive &&
      userData?.isSubscriptionNeeded &&
      (userData?.isTrainingFreeTrialExpired ||
        userData?.isDiaryFreeTrialExpired)
    ) {
      setOpenSubscriptionModal(true);
    }
  }, [userData]);

  useEffect(() => {
    if (currentLevel?.levelId)
      dispatch(getCurrentUserTrainings(currentLevel?.levelId));
  }, [dispatch, currentLevel?.levelId]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const scrollableElement = document.getElementById("dashboard-main");
    scrollableElement.scrollTo(0, 0);
  }, []);

  const calculateActiveDay = useCallback(() => {
    if (!trainingData || trainingData.length === 0) return 0;

    let activeDay = 0;

    for (let i = 0; i < trainingData?.length; i++) {
      const task = trainingData[i];
      const day = +task.day.split(" ")[1];
      if (!task.completedOn && day) {
        activeDay += day;
        break;
      }
    }

    // Limit activeDay to the maximum day in enabledDays
    const maxEnabledDay = Math.max(
      ...(currentLevel?.trainingCompletionPercentage === 100 &&
      currentLevel?.totalTrainingDays !== 0
        ? Array.from(
            { length: currentLevel?.completedTrainingDays },
            (_, i) => i + 1
          )
        : !userData?.isSubscriptionNeeded
        ? Array.from(
            {
              length:
                dayjs()
                  .startOf("day")
                  .diff(
                    dayjs(
                      dayjs(userData?.[`${currentLevel?.levelName}_start_date`])
                        .utc()
                        .format("YYYY-MM-DD")
                    ).startOf("day"),
                    "day"
                  ) + 1
            },
            (_, i) => i + 1
          )
        : !userData?.isTrainingFreeTrialExpired
        ? Array.from(
            {
              length: Math.min(
                5,
                dayjs()
                  .startOf("day")
                  .diff(
                    dayjs(
                      dayjs(userData?.[`${currentLevel?.levelName}_start_date`])
                        .utc()
                        .format("YYYY-MM-DD")
                    ).startOf("day"),
                    "day"
                  ) + 1
              )
            },
            (_, i) => i + 1
          )
        : [])
    );
    return Math.min(activeDay, maxEnabledDay);
  }, [trainingData, userData]);

  const [startDay, setStartDay] = useState(1); // Start displaying from day 1

  const days = Array.from({ length: trainingData?.length }, (_, i) => i + 1); // Days 1 to 30
  const visibleDaysCount = isMobile ? 5 : 8; // Number of days to show at a time
  const visibleDays = days.slice(startDay - 1, startDay - 1 + visibleDaysCount);
  const isTablet = useMediaQuery("(min-width: 600px) and (max-width: 900px)");

  useEffect(() => {
    if (trainingData) {
      const currentDay = calculateActiveDay(trainingData);
      setActiveDay(currentDay);
      if (currentDay > visibleDaysCount) {
        const pageIndex = Math.floor((currentDay - 1) / visibleDaysCount);
        // Calculate startDay for that page
        const calculatedStartDay = pageIndex * visibleDaysCount + 1;
        setStartDay(calculatedStartDay);
      } else {
        setStartDay(1); // Reset to 1 if currentDay is less than or equal to visibleDaysCount
      }
    }
  }, [trainingData]);

  const handleDayClick = (day) => {
    setActiveDay(day);
    setIsAudioFinished(false);
  };

  const currentActiveData = useMemo(() => {
    return trainingData?.find((item) => item.day === `Day ${activeDay}`);
  }, [trainingData, activeDay]);

  const allCompletedData = useMemo(() => {
    return trainingData
      ?.filter((item) => item.completedOn)
      ?.map((item) => +item.day.split(" ")?.[1]);
  }, [trainingData]);

  const handleNext = () => {
    if (startDay + visibleDaysCount - 1 < days.length) {
      setStartDay((prev) => prev + visibleDaysCount);
      setIsAudioFinished(false);
    }
  };

  const handlePrevious = () => {
    if (startDay > 1) {
      setStartDay((prev) => prev - visibleDaysCount);
      setIsAudioFinished(false);
    }
  };
  const isContentLocked =
    userData?.isTrainingFreeTrialExpired &&
    userData?.isSubscriptionNeeded &&
    currentLevel?.trainingCompletionPercentage !== 100;

  useEffect(() => {
    if (userData.activeLevel && !isContentLocked) {
      setSelectedLevel(userData.activeLevel);
      setActiveLevelForMobile(true);
    }
  }, [userData]);
  return (
    <Box
      pb={10}
      sx={{
        padding: isMobile ? "0" : "20px 0 40px 32px"
      }}
    >
      {((!activeLevelForMobile && isMobile) || !isMobile) && (
        <Box display="flex" flexDirection={"column"}>
          <Box
            sx={{
              ...(isMobile && {
                width: "100%",
                padding: "16px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "10px",
                color: "#FCFCFC"
              })
            }}
          >
            <Typography
              fontSize={isMobile ? "28px" : "30px"}
              fontWeight={500}
              color={styles.color}
            >
              Programs
            </Typography>
            <Typography
              color="#6B7280"
              fontSize={isMobile ? "16px" : "16px"}
              fontWeight={isMobile ? 600 : 500}
            >
              Where you learn how to create your own diet plan
            </Typography>
          </Box>
          <Box
            mt={3}
            gap={2}
            display={"flex"}
            flexDirection={!isTablet ? "row" : "column"}
          >
            <Box
              sx={{
                width: isTablet
                  ? "90%"
                  : !isMobile
                  ? { md: open ? "27%" : "24%" }
                  : "100%",
                borderRadius: "24px",
                ...(!isMobile && {
                  height: "calc(100vh - 40px)"
                }),
                background: styles.levelBackgrounds,
                margin: 2,
                display: "flex",
                flexDirection: "column",
                padding:
                  isTablet || isMobile
                    ? "20px"
                    : { md: "24px 11px", lg: "24px 16px" },
                overflowY: "auto",
                ...(isTablet && { height: "100%" })
              }}
              gap={4}
            >
              {allLevels?.map(
                ({
                  title,
                  trainingCompletionPercentage,
                  levelName,
                  totalTrainingDays,
                  levelActive,
                  description
                }) => (
                  <Button
                    fullWidth
                    onClick={async (e) => {
                      if (levelActive && levelName !== "level3")
                        handleLevelSelect(levelName);
                      if (
                        !levelActive ||
                        (!userData?.isTrainingFreeTrialExpired &&
                          userData?.isSubscriptionNeeded)
                      ) {
                        const levelId = allLevels?.find(
                          (item) => item?.levelName === levelName
                        )?.levelId;
                        if (
                          userData?.levelId?.toString() !== levelId?.toString()
                        ) {
                          setSnackbarMessage(
                            "Please complete all prior trainings in order to purchase."
                          );
                          setOpenSnackbar(true);
                          return;
                        }
                        if (levelName !== "level3") {
                          const data = await purchaseTraining(levelId);
                          window.location.href = data?.sessionUrl;
                        }
                      }
                    }}
                    sx={{
                      padding:
                        !(
                          userData?.isAdmin !== 1 &&
                          userData?.userType !== "Company" &&
                          !(
                            (levelActive && !userData?.isSubscriptionNeeded) ||
                            (trainingCompletionPercentage === 100 &&
                              totalTrainingDays !== 0)
                          )
                        ) || levelName === "level3"
                          ? "45px 24px"
                          : 3,
                      alignItems: "center",
                      border:
                        selectedLevel === levelName
                          ? "1px solid #368AD0"
                          : "1px solid #D1D5DB",
                      borderRadius: "16px",
                      backgroundColor:
                        selectedLevel === levelName
                          ? "#368AD0"
                          : // : isMobile
                            // ? styles.lightGreyColor
                            "transparent"
                    }}
                  >
                    <Box
                      width="100%"
                      display="flex"
                      gap="16px"
                      justifyContent="space-between"
                      textTransform="none"
                      alignItems="center"
                    >
                      <Box
                        textAlign={"start"}
                        gap="4px"
                        display="flex"
                        flexDirection="column"
                      >
                        {levelName === "level1" &&
                          !userData?.isTrainingFreeTrialExpired &&
                          userData?.isSubscriptionNeeded && (
                            <Box
                              textAlign="start"
                              sx={{
                                width: "110px",
                                background: "#368AD0",
                                padding: "2px 6px"
                              }}
                            >
                              <Typography
                                fontSize="10px"
                                textTransform="capitalize"
                                fontWeight="bold"
                                fontStyle="italic"
                                color={"#333333"}
                              >
                                FIRST 5 DAYS FREE
                              </Typography>
                            </Box>
                          )}
                        <Box>
                          <Typography
                            color={"#FCFCFC"}
                            fontSize="1rem"
                            fontWeight={600}
                          >
                            {title}
                          </Typography>
                        </Box>
                        {userData?.isAdmin !== 1 &&
                        userData?.userType !== "Company" &&
                        !(
                          (levelActive && !userData?.isSubscriptionNeeded) ||
                          (trainingCompletionPercentage === 100 &&
                            totalTrainingDays !== 0)
                        ) ? (
                          <>
                            <Box>
                              <Typography
                                width={"80%"}
                                color={"#FCFCFC"}
                                fontSize="12px"
                              >
                                {levelName === "level3"
                                  ? "Coming Soon!"
                                  : description}
                              </Typography>
                            </Box>

                            {levelName !== "level3" && (
                              <Button
                                // disabled={
                                //   (levelActive &&
                                //     !userData?.isSubscriptionNeeded) ||
                                //   (trainingCompletionPercentage === 100 &&
                                //     totalTrainingDays !== 0)
                                // }
                                sx={{
                                  mt: 1,
                                  width: 89,
                                  height: "23px",
                                  textTransform: "none",
                                  background:
                                    selectedLevel === levelName
                                      ? "#FCFCFC"
                                      : "#D1D5DB",
                                  color:
                                    selectedLevel === levelName
                                      ? "#64AEDD"
                                      : "black",
                                  gap: "10px"
                                }}
                              >
                                <Typography fontSize="12px" fontWeight={500}>
                                  Buy Now
                                </Typography>
                                <IconButton
                                  sx={{
                                    background:
                                      selectedLevel === levelName
                                        ? "#64AEDD"
                                        : "#14142B",
                                    borderRadius: "50%",
                                    padding: "1px"
                                  }}
                                >
                                  <ChevronRight
                                    sx={{
                                      height: 12,
                                      width: 12,
                                      color: "#FCFCFC"
                                    }}
                                  />
                                </IconButton>
                              </Button>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                        {(userData?.isAdmin === 1 ||
                          userData?.userType === "Company") &&
                          !levelActive && (
                            <Box>
                              <Typography
                                width={"80%"}
                                color={"#FCFCFC"}
                                fontSize="12px"
                              >
                                Complete the previous training to unlock this
                                program.
                              </Typography>
                            </Box>
                          )}
                      </Box>
                      <Box mr={1}>
                        {levelActive ? (
                          <CircularProgressStatic
                            levelName={levelName}
                            selected={selectedLevel === levelName}
                            percentage={trainingCompletionPercentage}
                          />
                        ) : (
                          <LockIcon />
                        )}
                      </Box>
                    </Box>
                  </Button>
                )
              )}
            </Box>
            <Snackbar
              open={openSnackbar}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              autoHideDuration={4000}
              onClose={() => setOpenSnackbar(false)}
            >
              <Alert
                onClose={() => setOpenSnackbar(false)}
                severity={
                  snackbarMessage.includes("Success") ? "success" : "error"
                }
                variant="filled"
                sx={{ width: "100%" }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
            {!isMobile && selectedLevel && (
              <Box
                display="flex"
                sx={{
                  width: !isMobile ? { md: open ? "68%" : "73%" } : "100%",
                  padding: "0 25px",
                  ...((!isTablet || !isMobile) && {
                    maxHeight: "calc(100vh - 40px)"
                  })
                }}
                flexDirection="column"
                gap={3}
                ml={isMobile ? 0 : 2}
              >
                {isContentLocked ? (
                  <Box
                    sx={{
                      background:
                        "radial-gradient(circle at 50% 50%, #368AD0, #2D5F87, #1A3A5C)",
                      width: "100%",
                      margin: "24px 16px",
                      height: "100%",
                      zIndex: 1,
                      borderRadius: "8px"
                    }}
                  >
                    <Box
                      display="flex"
                      borderRadius="8px"
                      justifyContent="center"
                      alignItems="center"
                      mr={1}
                      sx={{
                        width: "100%",
                        height: "100%",
                        backdropFilter: "blur(8px)" // blur effect
                      }}
                    >
                      <LockIcon
                        style={{ color: "#FCFCFC", height: 70, width: 70 }}
                      />
                    </Box>
                  </Box>
                ) : (
                  <>
                    <DaySelector
                      enabledDays={
                        currentLevel?.trainingCompletionPercentage === 100 &&
                        currentLevel?.totalTrainingDays !== 0
                          ? Array.from(
                              { length: currentLevel?.completedTrainingDays },
                              (_, i) => i + 1
                            )
                          : !userData?.isSubscriptionNeeded
                          ? Array.from(
                              {
                                length:
                                  dayjs()
                                    .startOf("day")
                                    .diff(
                                      dayjs(
                                        dayjs(
                                          userData?.[
                                            `${currentLevel?.levelName}_start_date`
                                          ]
                                        )
                                          .utc()
                                          .format("YYYY-MM-DD")
                                      ).startOf("day"),
                                      "day"
                                    ) + 1
                              },
                              (_, i) => i + 1
                            )
                          : !userData?.isTrainingFreeTrialExpired
                          ? Array.from(
                              {
                                length: Math.min(
                                  5,
                                  dayjs()
                                    .startOf("day")
                                    .diff(
                                      dayjs(
                                        dayjs(
                                          userData?.[
                                            `${currentLevel?.levelName}_start_date`
                                          ]
                                        )
                                          .utc()
                                          .format("YYYY-MM-DD")
                                      ).startOf("day"),
                                      "day"
                                    ) + 1
                                )
                              },
                              (_, i) => i + 1
                            )
                          : []
                      }
                      allCompletedData={allCompletedData}
                      activeDay={activeDay}
                      setActiveDay={setActiveDay}
                      startDay={startDay}
                      visibleDays={visibleDays}
                      visibleDaysCount={visibleDaysCount}
                      days={days}
                      setStartDay={setStartDay}
                      trainingData={trainingData}
                      handleDayClick={handleDayClick}
                      handleNext={handleNext}
                      handlePrevious={handlePrevious}
                    />
                    <Box
                      sx={{
                        ...((!isTablet || !isMobile) && {
                          overflowY: "auto",
                          maxHeight: "calc(100vh - 120px)",
                          position: "relative"
                        })
                      }}
                    >
                      <RenderHtml
                        isMobile={isMobile}
                        selectedLevel={selectedLevel}
                        currentLevel={currentLevel}
                        activeLevelForMobile={activeLevelForMobile}
                        setIsAudioFinished={setIsAudioFinished}
                        isAudioFinished={isAudioFinished}
                        styles={styles}
                        activeDay={activeDay}
                        activeData={currentActiveData}
                      />
                    </Box>
                  </>
                )}
              </Box>
            )}
          </Box>
        </Box>
      )}
      {activeLevelForMobile && isMobile && (
        <Box
          display="flex"
          sx={{
            width: "100%",
            padding: "10px 25px"
          }}
          flexDirection="column"
          gap={3}
        >
          <Box display="flex" alignItems="center" gap={"16%"}>
            <IconButton onClick={() => setActiveLevelForMobile(false)}>
              <ChevronLeft
                sx={{
                  width: 30,
                  height: 30,
                  color: styles.color
                }}
                width={30}
                height={30}
              />
            </IconButton>
            <Typography fontWeight={500} fontSize={"24px"}>
              {" "}
              {currentLevel?.title}{" "}
            </Typography>
          </Box>
          {isContentLocked ? (
            <Box
              sx={{
                background:
                  "radial-gradient(circle at 50% 50%, #368AD0, #2D5F87, #1A3A5C)",
                width: "100%",
                margin: "24px 16px",
                height: "400px",
                zIndex: 1,
                borderRadius: "8px"
              }}
            >
              <Box
                display="flex"
                borderRadius="8px"
                justifyContent="center"
                alignItems="center"
                mr={1}
                sx={{
                  width: "100%",
                  height: "100%",
                  backdropFilter: "blur(8px)" // blur effect
                }}
              >
                <LockIcon style={{ color: "#FCFCFC", height: 70, width: 70 }} />
              </Box>
            </Box>
          ) : (
            <>
              <DaySelector
                enabledDays={
                  currentLevel?.trainingCompletionPercentage === 100 &&
                  currentLevel?.totalTrainingDays !== 0
                    ? Array.from(
                        { length: currentLevel?.completedTrainingDays },
                        (_, i) => i + 1
                      )
                    : !userData?.isSubscriptionNeeded
                    ? Array.from(
                        {
                          length:
                            dayjs()
                              .startOf("day")
                              .diff(
                                dayjs(
                                  dayjs(
                                    userData?.[
                                      `${currentLevel?.levelName}_start_date`
                                    ]
                                  )
                                    .utc()
                                    .format("YYYY-MM-DD")
                                ).startOf("day"),
                                "day"
                              ) + 1
                        },
                        (_, i) => i + 1
                      )
                    : !userData?.isTrainingFreeTrialExpired
                    ? Array.from(
                        {
                          length: Math.min(
                            5,
                            dayjs()
                              .startOf("day")
                              .diff(
                                dayjs(
                                  dayjs(
                                    userData?.[
                                      `${currentLevel?.levelName}_start_date`
                                    ]
                                  )
                                    .utc()
                                    .format("YYYY-MM-DD")
                                ).startOf("day"),
                                "day"
                              ) + 1
                          )
                        },
                        (_, i) => i + 1
                      )
                    : []
                }
                allCompletedData={allCompletedData}
                activeDay={activeDay}
                setActiveDay={setActiveDay}
                startDay={startDay}
                visibleDays={visibleDays}
                visibleDaysCount={visibleDaysCount}
                days={days}
                setStartDay={setStartDay}
                trainingData={trainingData}
                handleDayClick={handleDayClick}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />
              <Box>
                <RenderHtml
                  isMobile={isMobile}
                  selectedLevel={selectedLevel}
                  currentLevel={currentLevel}
                  activeLevelForMobile={activeLevelForMobile}
                  setIsAudioFinished={setIsAudioFinished}
                  isAudioFinished={isAudioFinished}
                  styles={styles}
                  activeDay={activeDay}
                  activeData={currentActiveData}
                />
              </Box>{" "}
            </>
          )}
        </Box>
      )}
      {(isMobile ? selectedLevel && activeLevelForMobile : selectedLevel) &&
        !isContentLocked &&
        currentLevel?.link && (
          <Box
            sx={{
              position: "fixed",
              bottom: { xs: 70, md: 20 },
              right: 16,
              zIndex: 1000
            }}
          >
            <IconButton
              variant="contained"
              sx={{
                display: "flex",
                backgroundColor: "#FF4000",
                borderRadius: "24px",
                color: "#FCFCFC",
                padding: "7px 12px",
                fontSize: "16px",
                textTransform: "none",
                gap: "10px",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#FF7043"
                }
              }}
              onClick={() => {
                const url = currentLevel?.link.startsWith("http")
                  ? currentLevel?.link
                  : `http://${currentLevel?.link}`;
                window.open(url, "_blank");
              }}
            >
              <QuestionAnswer />
              {/* <Typography
                sx={{
                  background: "#FCFCFC",
                  color: "#506DF0",
                  borderRadius: "50%",
                  padding: "7px 12px"
                }}
              >
                C
              </Typography>
              <Typography color="#FCFCFC">Join Us</Typography> */}
            </IconButton>
          </Box>
        )}
    </Box>
  );
};

export default Programs;
