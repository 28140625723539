import styled from "@emotion/styled";
import { CheckCircle, Circle, Close, Star } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { addSubscription } from "../../api/userApi";
import { selectCurrentUser } from "../../features/auth/authSelectors";

const StyledTextField = styled(TextField)(({ theme }) => ({
  input: {
    color: "#FCFCFC"
  },
  "& .MuiOutlinedInput-root": {
    border: "2px dotted #A4A4A4",
    "& fieldset": {
      color: "#9CA3AF"
    },
    "&:hover fieldset": {
      borderColor: "#FCFCFC",
      border: "none"
    },
    "&.Mui-focused fieldset": {
      color: "#9CA3AF",
      borderColor: "#FCFCFC",
      border: "none"
    }
  }
}));

const SubscriptionModal = ({ open, onClose, isMobile = false }) => {
  const [subscriptionType, setSubscriptionType] = useState("monthly");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [couponModalOpen, setCouponModalOpen] = useState(false);
  const user = useSelector(selectCurrentUser);
  const [inputCouponValue, setInputCouponValue] = useState("");
  const handleCouponChange = (event) => {
    // Apply transformations based on CPOVP, REFDS, MNGFQ requirements here
    // For example:
    if (event.target.value.length > 23) return;
    const existing = event.target.value.replaceAll("-", "").toUpperCase();
    // Insert hyphens after every 5 characters
    const formattedValue = existing.replace(/(.{5})(?!-)/g, "$1-");

    setInputCouponValue(formattedValue);
  };

  const handleSubscriptionChange = (event) => {
    setSubscriptionType(event.target.value);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSubscribe = async () => {
    try {
      const { session } = await addSubscription(subscriptionType, user?.email);
      if (session?.url) window.location.href = session?.url;
      else {
        setSnackbarMessage(session.message);
        setOpenSnackbar(true);
        setTimeout(() => {
          onClose();
          handleCloseSnackbar();
        }, 3000);
      }
    } catch (err) {
      setSnackbarMessage(err.error);
      setOpenSnackbar(true);
    }
  };
  return isMobile ? (
    open ? (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1300,
          overflowY: "auto"
        }}
      >
        <Box
          sx={{
            position: "relative",
            top: "60%",
            left: "50%",
            overflow: "auto",
            width: { md: "42%", xs: "100%" },
            borderRadius: 2,
            transform: "translate(-50%, -50%)"
          }}
        >
          <Box
            sx={{
              padding: { xs: "28px", md: 0 },
              position: "relative",
              bgcolor: "#000",
              borderRadius: "16px 16px 0 0",
              boxShadow: 24,
              overflow: "hidden"
            }}
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: "url(/images/sky.png)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                opacity: 0.5,
                zIndex: 1
              }}
            />
            <Box
              sx={{
                position: "relative",
                zIndex: 2
              }}
            >
              <Box
                display="flex"
                justifyContent="end"
                alignItems="flex-end"
                width="100%"
              >
                {" "}
                <IconButton
                  sx={{
                    svg: {
                      fill: "#FCFCFC"
                    }
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClose();
                  }}
                >
                  <Close />
                </IconButton>
              </Box>
              <Box
                sx={{ padding: { sm: 0, md: "0 95px" } }}
                display={"flex"}
                gap="10px"
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box
                  component="img"
                  src="/images/threepillars.png"
                  alt="base"
                  sx={{
                    width: "68px",
                    height: "68px",
                    objectFit: "cover"
                  }}
                />
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap="10px"
                  flexDirection="column"
                >
                  <Typography
                    fontWeight={600}
                    fontSize={"21px"}
                    color="#FCFCFC"
                  >
                    Keep Going!
                  </Typography>
                  <Typography
                    fontWeight={450}
                    textAlign={"center"}
                    fontSize={"15px"}
                    color="#FCFCFC"
                  >
                    Your free trial has ended.
                    <br />
                  </Typography>
                  <Typography
                    textAlign={"center"}
                    mt={-1.5}
                    fontWeight={400}
                    fontSize={"12.5px"}
                    color="#FCFCFC"
                  >
                    Please select a plan below to continue using the Three
                    Pillars Method Platform.
                  </Typography>
                  {/* <Box
                    display="flex"
                    justifyContent={"center"}
                    alignItems="center"
                    gap={2}
                  >
                    <Typography fontSize={24}>🥗</Typography>
                    <Typography fontSize={"12px"} color="#FCFCFC">
                      <b>Meals of the day:</b> Get inspired with our daily MODs
                      and share yours with the community.
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent={"center"}
                    alignItems="center"
                    gap={2}
                  >
                    <Typography fontSize={24}> 🔊</Typography>
                    <Typography fontSize={"12px"} color="#FCFCFC">
                      <b>30 Day Trainings:</b> Level up your diet with (3) 30
                      day trainings led by EC from Optimize Nutrition.
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent={"center"}
                    alignItems="center"
                    gap={2}
                  >
                    <Typography fontSize={24}> 📒</Typography>
                    <Typography fontSize={"12px"} color="#FCFCFC">
                      <b>Simplified logging:</b> We make it easy to track your
                      progress through the diet journey.
                    </Typography>
                  </Box> */}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            //   height={"40%"}
            sx={{
              background: "#333333",
              display: "flex",
              padding: "24px 47px",
              borderRadius: "0 0 16px 16px",
              flexDirection: "column"
            }}
          >
            <FormControl component="fieldset">
              <RadioGroup
                value={subscriptionType}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "30px",
                  justifyContent: "center"
                }}
                onChange={handleSubscriptionChange}
              >
                <FormControlLabel
                  style={{
                    backgroundColor: "#1C1C1C",
                    borderRadius: "8px",
                    padding: "35px 27px",
                    margin: 0,
                    height: "100%"
                  }}
                  value="monthly"
                  control={
                    <Radio
                      icon={<Circle sx={{ fill: "#FCFCFC" }} />}
                      checkedIcon={<CheckCircle sx={{ fill: "#368AD0" }} />}
                    />
                  }
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        color: "#FCFCFC"
                      }}
                    >
                      <Typography variant="h6" fontWeight="bold">
                        $9.99/Month
                      </Typography>
                      <Typography variant="body2" fontSize="12px">
                        (Continued access to MODs & Daily Nutrition Logging)
                      </Typography>
                    </Box>
                  }
                  sx={{
                    border:
                      subscriptionType === "monthly"
                        ? "2px solid #368AD0"
                        : "none",
                    borderRadius: "4px",
                    padding: 1,
                    marginBottom: 2,
                    backgroundColor:
                      subscriptionType === "monthly"
                        ? "#2B2B2B"
                        : "transparent",
                    "&:hover": {
                      backgroundColor: "#2B2B2B"
                    }
                  }}
                />
                <FormControlLabel
                  style={{
                    backgroundColor: "#1C1C1C",
                    borderRadius: "8px",
                    margin: 0,
                    height: "100%",
                    padding: "0px 0px 17px 16px"
                  }}
                  value="yearly"
                  control={
                    <Radio
                      icon={<Circle sx={{ fill: "#FCFCFC" }} />}
                      checkedIcon={<CheckCircle sx={{ fill: "#368AD0" }} />}
                    />
                  }
                  label={
                    <>
                      <Box
                        display="flex"
                        justifyContent="end"
                        width="100%"
                        alignItems="center"
                      >
                        <Typography
                          display={"flex"}
                          sx={{
                            background: "#FCFCFC",
                            padding: "0 8px",
                            alignItems: "center",
                            gap: "5px",
                            borderRadius: "0 8px 0 8px"
                          }}
                          color="black"
                        >
                          <Star sx={{ fill: "#F1B44C", fontSize: "14px" }} />
                          <Typography variant="caption" lineHeight={0}>
                            Save 50%
                          </Typography>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          padding: "12px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          color: "#FCFCFC"
                        }}
                      >
                        <Typography variant="h6" fontWeight="bold">
                          $99.00/Year
                        </Typography>
                        <Typography variant="body2" fontSize="12px">
                          (Continued access to MODs & Daily Nutrition Logging
                          PLUS the full #800gChallenge Program ($99) Free!)
                        </Typography>
                      </Box>
                    </>
                  }
                  sx={{
                    border:
                      subscriptionType === "yearly"
                        ? "2px solid #368AD0"
                        : "none",
                    borderRadius: "4px",
                    padding: 1,
                    marginBottom: 2,
                    backgroundColor:
                      subscriptionType === "yearly" ? "#2B2B2B" : "transparent",
                    "&:hover": {
                      backgroundColor: "#2B2B2B"
                    }
                  }}
                />
              </RadioGroup>
            </FormControl>
            <Box mt={3}>
              <Button
                sx={{
                  background: "#368AD0",
                  color: "#FCFCFC",
                  textTransform: "none",
                  width: "100%",
                  fontWeight: "bold"
                  // height: "54px"
                }}
                onClick={handleSubscribe}
              >
                Subscribe
              </Button>
            </Box>
            <Box mt={2} display="flex" justifyContent="center">
              <Button
                sx={{
                  width: "70%",
                  color: "#368AD0",
                  textTransform: "none",
                  fontWeight: "bold"
                }}
                variant="none"
                onClick={() => {
                  setCouponModalOpen(true);
                }}
              >
                Apply Coupon
              </Button>
            </Box>
          </Box>
          {couponModalOpen && (
            <Modal
              disableAutoFocus
              disableEnforceFocus
              open={couponModalOpen}
              onClose={() => {
                setCouponModalOpen(false);
                onClose();
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  width: "100%",
                  // height: { xs: "35%", md: "30%" },
                  // width: { xs: "65%", md: "30%" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  borderRadius: 3,
                  background: "#1C1C1C",
                  color: "#FCFCFC",
                  transform: "translate(-50%, -50%)"
                }}
              >
                <Box
                  display="flex"
                  width="100%"
                  justifyContent="end"
                  alignItems="flex-end"
                >
                  <IconButton
                    sx={{
                      svg: {
                        fill: "#FCFCFC"
                      }
                    }}
                    onClick={() => {
                      setCouponModalOpen(false);
                    }}
                  >
                    <Close />
                  </IconButton>
                </Box>
                <Typography fontSize={"24px"} fontWeight={600}>
                  Apply Coupon
                </Typography>
                <Typography fontSize={"12px"} fontWeight={400} color="#D1D5DB">
                  Please enter the coupon code to activate the subscription
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={3}
                  width={"100%"}
                  padding={2}
                >
                  <StyledTextField
                    fullWidth
                    value={inputCouponValue}
                    variant="outlined"
                    name="coupon"
                    placeholder="Enter the coupon code"
                    margin="normal"
                    onChange={handleCouponChange}
                  />
                  <Button
                    fullWidth
                    sx={{
                      backgroundColor: "#368AD0",
                      textTransform: "none",
                      fontWeight: "bold"
                    }}
                    variant="contained"
                    onClick={handleSubscribe}
                  >
                    Apply now
                  </Button>
                </Box>
              </Box>
            </Modal>
          )}
          {/* {couponModalOpen && (
          <Box
            sx={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "300px",
              backgroundColor: "#1C1C1C",
              padding: "20px",
              borderRadius: "8px",
              zIndex: 1500
            }}
          >
            <StyledTextField
              value={inputCouponValue}
              onChange={handleCouponChange}
              placeholder="Enter Coupon Code"
              fullWidth
            />
            <Button
              sx={{
                marginTop: "16px",
                backgroundColor: "#368AD0",
                color: "#FCFCFC",
                textTransform: "none",
                width: "100%"
              }}
              onClick={handleSubscribe}
            >
              Apply Now
            </Button>
          </Box>
        )} */}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            sx={{ top: 0 }}
          >
            <Alert
              severity={
                snackbarMessage?.includes("success" || "Success")
                  ? "success"
                  : "error"
              }
              onClose={handleCloseSnackbar}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    ) : (
      <></>
    )
  ) : (
    <Modal open={open} onClose={onClose} disableAutoFocus disableEnforceFocus>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          // overflow: "auto",
          height: { xs: "80%", md: "70%" },
          width: { md: "42%", xs: "100%" },
          borderRadius: 2,
          border: "none",
          transform: "translate(-50%, -50%)"
        }}
      >
        <Box
          sx={{
            padding: { xs: "28px", md: 0 },
            position: "relative",
            bgcolor: "#000",
            borderRadius: "16px 16px 0 0",
            boxShadow: 24,
            overflow: "hidden" // Ensures pseudo-element stays within the box
          }}
          display={"flex"}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          //   height={"65%"}
          flexDirection={"column"}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: "url(/images/sky.png)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              opacity: { xs: 0.5, md: 0.5 }, // Set the opacity for the background image
              zIndex: 1 // Ensure it is behind the content
            }}
          />
          <Box
            sx={{
              position: "relative",
              width: "100%",
              zIndex: 2 // Content stays on top of the background
            }}
          >
            <Box
              display="flex"
              justifyContent="end"
              alignItems="flex-end"
              width="100%"
            >
              <IconButton
                sx={{
                  svg: {
                    fill: "#FCFCFC"
                  }
                }}
                onClick={onClose}
              >
                <Close />
              </IconButton>
            </Box>
            <Box
              sx={{ padding: { sm: 0, md: "10px 95px" } }}
              display={"flex"}
              gap="10px"
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box
                component="img"
                src="/images/threepillars.png"
                alt="base"
                sx={{
                  width: "68px",
                  height: "68px",
                  objectFit: "cover"
                }}
              />
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                flexDirection="column"
              >
                <Typography
                  textAlign="center"
                  fontWeight={500}
                  fontSize={"34px"}
                  color="#FCFCFC"
                >
                  Keep Going!
                </Typography>
                <Typography fontWeight={450} fontSize={"15px"} color="#FCFCFC">
                  Your free trial has ended.
                  <br />
                </Typography>
                <Typography
                  mt={-1.5}
                  fontWeight={400}
                  fontSize={"13px"}
                  color="#FCFCFC"
                >
                  Please select a plan below to continue using the Three Pillars
                  Method Platform.
                </Typography>
                {/* <Box
                  mt={2}
                  display="flex"
                  justifyContent={"center"}
                  alignItems="center"
                  gap={2}
                >
                  <Typography fontSize={25}>🥗</Typography>
                  <Typography fontSize={"14px"} color="#FCFCFC">
                    <b>Meals of the day:</b> Get inspired with our daily MODs
                    and share yours with the community.
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent={"center"}
                  alignItems="center"
                  gap={2}
                >
                  <Typography fontSize={25}> 🔊</Typography>
                  <Typography fontSize={"14px"} color="#FCFCFC">
                    <b>30 Day Trainings:</b> Level up your diet with (3) 30 day
                    trainings led by EC from Optimize Nutrition.
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent={"center"}
                  alignItems="center"
                  gap={2}
                >
                  <Typography fontSize={25}> 📒</Typography>
                  <Typography fontSize={"14px"} color="#FCFCFC">
                    <b> Simplified logging:</b> We make it easy to track your
                    progress through the diet journey.
                  </Typography>
                </Box> */}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          height={"55%"}
          sx={{
            background: "#333333",
            display: "flex",
            padding: "24px 47px",
            borderRadius: "0 0 16px 16px",
            flexDirection: "column"
          }}
        >
          <FormControl component="fieldset">
            <RadioGroup
              value={subscriptionType}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "30px",
                alignItems: "center",
                justifyContent: "center"
              }}
              onChange={handleSubscriptionChange}
            >
              <FormControlLabel
                style={{
                  width: "40%",
                  backgroundColor: "#1C1C1C",
                  borderRadius: "8px",
                  padding: "37px 20px 37px 2px",
                  margin: 0,
                  height: "100%"
                }}
                value="monthly"
                control={
                  <Radio
                    icon={<Circle sx={{ fill: "#FCFCFC" }} />}
                    checkedIcon={<CheckCircle sx={{ fill: "#368AD0" }} />}
                  />
                }
                label={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      color: "#FCFCFC"
                    }}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      $9.99/Month
                    </Typography>
                    <Typography variant="body2" fontSize="12px">
                      (Continued access to MODs & Daily Nutrition Logging)
                    </Typography>
                  </Box>
                }
                sx={{
                  border:
                    subscriptionType === "monthly"
                      ? "2px solid #368AD0"
                      : "none",
                  borderRadius: "4px",
                  padding: 1,
                  marginBottom: 2,
                  backgroundColor:
                    subscriptionType === "monthly" ? "#2B2B2B" : "transparent",
                  "&:hover": {
                    backgroundColor: "#2B2B2B"
                  }
                }}
              />
              <FormControlLabel
                style={{
                  width: "40%",
                  backgroundColor: "#1C1C1C",
                  borderRadius: "8px",
                  margin: 0,
                  height: "100%",
                  padding: 1
                }}
                value="yearly"
                control={
                  <Radio
                    icon={<Circle sx={{ fill: "#FCFCFC" }} />}
                    checkedIcon={<CheckCircle sx={{ fill: "#368AD0" }} />}
                  />
                }
                label={
                  <>
                    {" "}
                    <Box
                      display="flex"
                      justifyContent="end"
                      width="100%"
                      mb={0.4}
                      alignItems="center"
                    >
                      <Typography
                        display={"flex"}
                        sx={{
                          background: "#FCFCFC",
                          padding: "0 8px",
                          alignItems: "center",
                          gap: "5px",
                          borderRadius: "0 8px 0 8px"
                        }}
                        color="black"
                      >
                        <Star sx={{ fill: "#F1B44C", fontSize: "14px" }} />
                        <Typography variant="caption" lineHeight={0}>
                          Save 50%
                        </Typography>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "5px 30px 17px 0px",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        color: "#FCFCFC"
                      }}
                    >
                      {" "}
                      <Typography variant="h6" fontWeight="bold">
                        $99.00/Year
                      </Typography>
                      <Typography variant="body2" fontSize="12px">
                        (Continued access to MODs & Daily Nutrition Logging PLUS
                        the full #800gChallenge Program ($99) Free!)
                      </Typography>
                    </Box>
                  </>
                }
                sx={{
                  border:
                    subscriptionType === "yearly"
                      ? "2px solid #368AD0"
                      : "none",
                  borderRadius: "8px",
                  padding: 1,
                  marginBottom: 2,
                  backgroundColor:
                    subscriptionType === "yearly" ? "#2B2B2B" : "transparent",
                  "&:hover": {
                    backgroundColor: "#2B2B2B"
                  }
                }}
              />
            </RadioGroup>
          </FormControl>
          <Box mt={4} display="flex" justifyContent="center">
            <Button
              sx={{
                width: { xs: "100%", md: "70%" },
                backgroundColor: "#368AD0",
                textTransform: "none",
                fontWeight: "bold"
              }}
              variant="contained"
              onClick={handleSubscribe}
            >
              Subscribe
            </Button>
          </Box>
          <Box mt={2} display="flex" justifyContent="center">
            <Button
              sx={{
                width: "70%",
                color: "#368AD0",
                textTransform: "none",
                fontWeight: "bold"
              }}
              variant="none"
              onClick={() => {
                setCouponModalOpen(true);
              }}
            >
              Apply Coupon
            </Button>
          </Box>

          {couponModalOpen && (
            <Modal
              disableAutoFocus
              disableEnforceFocus
              open={couponModalOpen}
              onClose={() => {
                setCouponModalOpen(false);
                onClose();
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  height: { xs: "35%", md: "30%" },
                  width: { xs: "65%", md: "30%" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  borderRadius: 3,
                  background: "#1C1C1C",
                  color: "#FCFCFC",
                  transform: "translate(-50%, -50%)"
                }}
              >
                <Box
                  display="flex"
                  justifyContent="end"
                  alignItems="flex-end"
                  width="100%"
                >
                  <IconButton
                    sx={{
                      svg: {
                        fill: "#FCFCFC"
                      }
                    }}
                    onClick={() => {
                      setCouponModalOpen(false);
                    }}
                  >
                    <Close />
                  </IconButton>
                </Box>
                <Typography fontSize={"24px"} fontWeight={600}>
                  Apply Coupon
                </Typography>
                <Typography fontSize={"12px"} fontWeight={400} color="#D1D5DB">
                  Please enter the coupon code to activate the subscription
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={3}
                  width={"100%"}
                  padding={2}
                >
                  <StyledTextField
                    fullWidth
                    value={inputCouponValue}
                    variant="outlined"
                    name="coupon"
                    placeholder="Enter the coupon code"
                    margin="normal"
                    onChange={handleCouponChange}
                  />
                  <Button
                    fullWidth
                    sx={{
                      backgroundColor: "#368AD0",
                      textTransform: "none",
                      fontWeight: "bold"
                    }}
                    variant="contained"
                    onClick={handleSubscribe}
                  >
                    Apply now
                  </Button>
                </Box>
              </Box>
            </Modal>
          )}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={
                snackbarMessage?.includes("success" || "Success")
                  ? "success"
                  : "error"
              }
              sx={{ width: "100%", top: 0, right: 0 }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </Modal>
  );
};

export default SubscriptionModal;
