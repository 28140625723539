import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  login,
  register,
  logout,
  forgetPassword,
  resetPassword,
  verifyIdentity,
  fetchAllCards,
  fetchAllBillingDetails
} from "../../api/authApi";
import {
  completeUserProfile,
  fetchUser,
  fetchUserPoints
} from "../../api/userApi";

// Thunks for handling async actions
export const loginUser = createAsyncThunk(
  "auth/login",
  async (credentials, thunkAPI) => {
    try {
      const response = await login(credentials);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const registerUser = createAsyncThunk(
  "auth/register",
  async (userData, thunkAPI) => {
    try {
      const response = await register(userData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const verifyIdentityUser = createAsyncThunk(
  "auth/verifyIdentity",
  async (sessionData, thunkAPI) => {
    try {
      const response = await verifyIdentity(sessionData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchCurrentUser = createAsyncThunk(
  "auth/fetchUser",
  async (date, thunkAPI) => {
    try {
      const userId = JSON.parse(localStorage.getItem("user") || {}).userId;
      const response = await fetchUser(userId, date);
      localStorage.setItem("user", JSON.stringify(response.user));
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getPointList = createAsyncThunk(
  "auth/getPointList",
  async ({ month, year, levelId }, thunkAPI) => {
    try {
      const response = await fetchUserPoints(month, year, levelId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const completeProfile = createAsyncThunk(
  "auth/completeProfile",
  async (userData, thunkAPI) => {
    try {
      const response = await completeUserProfile(
        userData.formData,
        userData.userId
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "auth/logout",
  async (_, thunkAPI) => {
    try {
      const response = await logout();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const forgetPasswordUser = createAsyncThunk(
  "auth/forgetPassword",
  async (email, thunkAPI) => {
    try {
      const response = await forgetPassword(email);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const changePasswordUser = createAsyncThunk(
  "auth/resetPassword",
  async (userData, thunkAPI) => {
    try {
      const response = await resetPassword(userData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchUserCards = createAsyncThunk(
  "auth/getCards",
  async (_, thunkAPI) => {
    try {
      const response = await fetchAllCards();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchUserBillingDetails = createAsyncThunk(
  "auth/fetchUserBillingDetails",
  async (_, thunkAPI) => {
    try {
      const response = await fetchAllBillingDetails();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Initial state
const initialState = {
  user:
    JSON.parse(
      ["undefined", "null", undefined, null].includes(
        localStorage.getItem("user")
      )
        ? null
        : localStorage.getItem("user")
    ) || null,
  isAuthenticated: !!localStorage.getItem("token"),
  loading: false,
  cards: [],
  billingDetails: {},
  pointLists: {},
  error: null
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUserUncompletedCount: (state) => {
      if (state.user.pendingTrainingDays)
        state.user.pendingTrainingDays = state.user.pendingTrainingDays - 1;
    }
  },
  extraReducers: (builder) => {
    builder
      // Login
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Register
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Fetch user
      .addCase(fetchCurrentUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(fetchCurrentUser.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.isAuthenticated = false;
      })
      .addCase(completeProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(completeProfile.fulfilled, (state, action) => {
        if (action.payload?.user?.token) {
          state.user = action.payload.user;
        }
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(completeProfile.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.loading = false;
      })
      // Logout
      .addCase(logoutUser.fulfilled, (state) => {
        state.user = null;
        state.isAuthenticated = false;
      })
      .addCase(forgetPasswordUser.fulfilled, (state) => {
        state.user = null;
        state.isAuthenticated = false;
      })
      .addCase(changePasswordUser.fulfilled, (state) => {
        state.user = null;
        state.isAuthenticated = false;
      })
      .addCase(fetchUserCards.fulfilled, (state, action) => {
        state.cards = action.payload.cards;
      })
      .addCase(getPointList.fulfilled, (state, action) => {
        state.pointLists = action.payload;
      })
      .addCase(fetchUserBillingDetails.fulfilled, (state, action) => {
        state.billingDetails = action.payload;
      });
  }
});
export const { updateUserUncompletedCount } = authSlice.actions;

export default authSlice.reducer;
