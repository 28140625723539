import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectIsAuthenticated
} from "../../features/auth/authSelectors";
import { fetchCurrentUser } from "../../features/auth/authSlice";
import dayjs from "dayjs";
import { UserProvider } from "../Extra/UserContext";
import { FailedPaymentModal } from "../Extra/FailedPaymentModal";

const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const currentUser = useSelector(selectCurrentUser);
  const [isFailedPayment, setIsFailedPayment] = useState(false);

  useEffect(() => {
    dispatch(fetchCurrentUser(dayjs().format("YYYY-MM-DD")));
  }, [dispatch]);

  useEffect(() => {
    if (currentUser?.isFailedPayment) {
      setIsFailedPayment(true);
    }
  }, [currentUser]);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      {isFailedPayment && (
        <FailedPaymentModal
          open={isFailedPayment}
          onClose={() => setIsFailedPayment(false)}
        />
      )}
      <UserProvider currentUser={currentUser} children={children} />
    </>
  );
};

export default PrivateRoute;
