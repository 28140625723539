const options800gms = [
  { value: "veggies", label: "Veggies" },
  { value: "fruit", label: "Fruit" },
  { value: "avocado", label: "Avocado/olive" },
  { value: "leafygreen", label: "Leafy greens" },
  { value: "beans", label: "Beans/lentils" },
  { value: "banana", label: "Banana/potato" },
  { value: "potato", label: "Mashed items" },
  { value: "others", label: "Others" }
];

const optionsProteins = [
  { value: "chicken", label: "Chicken" },
  { value: "dryfruit", label: "Dry Fruit" },
  { value: "milk", label: "Milk" },
  { value: "egg", label: "Egg" },
  { value: "others", label: "Others" }
];

export { options800gms, optionsProteins };
