import {
  Box,
  FormControl,
  MenuItem,
  Select,
  styled,
  Typography,
  useMediaQuery
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import useThemeColor from "../../utils/useTheme";
import DateRangePicker from "./DateRangePicker";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllDiaryOutOf800g,
  selectAllDiaryOutOfProtein,
  selectAllDiaryStats
} from "../../features/diary/diarySelectors";
import { getCurrentUserDiaryStats } from "../../features/diary/diarySlice";
import DiaryTracker from "../Extra/DiaryTracker";
import dayjs from "dayjs";
import { ReactComponent as TrophyIcon } from "../../assets/goal-trophy.svg";
import LinearProgress, {
  linearProgressClasses
} from "@mui/material/LinearProgress";
import { useTheme } from "@emotion/react";

const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, activeColor, background }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: background
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: activeColor
    }
  })
);

const StatsList = ({ level: { levelName, levelId } }) => {
  const styles = useThemeColor();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [value, setValue] = useState("7days");
  const dispatch = useDispatch();
  const allStats = useSelector(selectAllDiaryStats);
  const total800g = useSelector(selectAllDiaryOutOf800g);
  const totalProteins = useSelector(selectAllDiaryOutOfProtein);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    if ((value === "custom" && startDate && endDate) || value !== "custom")
      dispatch(
        getCurrentUserDiaryStats({
          timeFrame: value,
          levelId,
          date: dayjs().format("YYYY-MM-DD"),
          ...(value === "custom" && {
            startDate: dayjs(startDate).format("YYYY-MM-DD"),
            endDate: dayjs(endDate).format("YYYY-MM-DD")
          })
        })
      );
  }, [dispatch, value, levelId, startDate, endDate]);

  const calculateAverage800gms = useMemo(() => {
    const sum = allStats?.reduce(
      (prev, curr) => prev + (curr.totalEntry800g || 0),
      0
    );
    return {
      average: Math.round(sum / allStats?.length) || 0,
      total: allStats[0]?.outOf800g || total800g
    };
  }, [allStats, total800g]);

  const calculateAverageProtein = useMemo(() => {
    const sum = allStats?.reduce(
      (prev, curr) => prev + (curr.totalProtein || 0),
      0
    );
    return {
      average: Math.round(sum / allStats?.length) || 0,
      total: allStats[0]?.outOfProtein || totalProteins
    };
  }, [allStats, totalProteins]);
  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={isMobile ? "column" : "row"}
      >
        <Typography fontSize={"24px"} color={styles.color}>
          Average for set time period
        </Typography>
        <FormControl
          sx={{
            width: { xs: "100%", md: "25%" },
            marginTop: "15px",
            svg: {
              fill: styles.color
            },
            ".MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: styles.lightGreyColor
              },
              "&:hover fieldset": {
                borderColor: styles.color
              },
              "&.Mui-focused fieldset": {
                borderColor: styles.color
              }
            }
          }}
        >
          <Select
            labelId="range-label"
            name="range"
            value={value}
            MenuProps={{
              MenuListProps: {
                sx: {
                  padding: 0,
                  ".Mui-selected": {
                    color: `${styles.background} !important`,
                    background: `${styles.color} !important`
                  }
                }
              }
            }}
            sx={{
              color: styles.color,
              marginTop: 0, // Remove any margin
              paddingTop: 0 // Remove any padding
            }}
            onChange={(e) => setValue(e.target.value)}
          >
            <MenuItem
              sx={{
                backgroundColor: styles.background,
                color: styles.color,
                paddingY: 1,
                "&:hover": {
                  backgroundColor: styles.color,
                  color: styles.background
                }
              }}
              value="today"
            >
              Today
            </MenuItem>
            <MenuItem
              sx={{
                backgroundColor: styles.background,
                color: styles.color,
                paddingY: 1,
                "&:hover": {
                  backgroundColor: styles.color,
                  color: styles.background
                }
              }}
              value="7days"
            >
              7 Days
            </MenuItem>
            <MenuItem
              sx={{
                backgroundColor: styles.background,
                color: styles.color,
                paddingY: 1,
                "&:hover": {
                  backgroundColor: styles.color,
                  color: styles.background
                }
              }}
              value="30days"
            >
              30 Days
            </MenuItem>
            <MenuItem
              sx={{
                backgroundColor: styles.background,
                color: styles.color,
                paddingY: 1,
                "&:hover": {
                  backgroundColor: styles.color,
                  color: styles.background
                }
              }}
              value="custom"
            >
              Custom time
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      {value === "custom" && (
        <Box display="flex" justifyContent="flex-end">
          <DateRangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Box>
      )}
      <Box
        display="flex"
        justifyContent="center"
        sx={{
          borderRadius: "16px",
          backgroundColor: styles.diaryTracker
        }}
      >
        <DiaryTracker
          title={"#800gChallenge"}
          nonEditable
          levelName={levelName}
          strokeColor={"#F6541C"}
          value={calculateAverage800gms?.average}
          maxValue={calculateAverage800gms?.total}
          heightX={levelName === "level2" ? (isMobile ? 130 : 210) : 250}
          widthX={levelName === "level2" ? (isMobile ? 190 : 300) : 350}
        />
        {levelName === "level2" && (
          <DiaryTracker
            title={"Protein"}
            nonEditable
            levelName={levelName}
            strokeColor={"#368AD0"}
            value={calculateAverageProtein?.average}
            maxValue={calculateAverageProtein?.total}
            heightX={levelName === "level2" ? (isMobile ? 130 : 210) : 250}
            widthX={levelName === "level2" ? (isMobile ? 190 : 300) : 350}
          />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        mb={2}
        sx={{
          ...(!isMobile && {
            overflowY: "auto",
            maxHeight: "calc(100vh - 120px)"
          })
        }}
      >
        {allStats?.map((item) => (
          <>
            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              sx={{
                background: styles.modBackgroundBlocks,
                borderRadius: "16px",
                p: 3
              }}
            >
              <Box display="flex" gap="10px" alignItems="center">
                <Typography fontWeight={600} fontSize={"18px"}>
                  {dayjs(item?.currentDayDate).format("MMMM D")}
                </Typography>
                {+item.totalEntry800g >= +item.outOf800g &&
                  (levelName === "level2"
                    ? +item.totalProtein >= +item.outOfProtein
                    : true) && (
                    <Box
                      display="flex"
                      gap="4px"
                      alignItems="center"
                      sx={{
                        background: "#F1B44C",
                        px: 1,
                        border: `1px solid ${styles.color}`,
                        borderRadius: "100px"
                      }}
                    >
                      <TrophyIcon />
                      <Typography color="#14142B" fontSize={"12px"}>
                        Goal Achieved
                      </Typography>
                    </Box>
                  )}
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography fontWeight={600} color="#F6541C" fontSize="16px">
                  {" "}
                  800g
                </Typography>
                <Box>
                  <Typography fontWeight={600} fontSize="16px">
                    {item.totalEntry800g || 0}/{item.outOf800g}g
                  </Typography>
                </Box>
              </Box>
              <BorderLinearProgress
                activeColor="#F6541C"
                background={styles.lightBlack}
                variant="determinate"
                value={
                  ((item.totalEntry800g || 0) >= item.outOf800g
                    ? 1
                    : (item.totalEntry800g || 0) / item.outOf800g) * 100
                }
              />
              {levelName === "level2" && (
                <>
                  {" "}
                  <Box display="flex" mt={1} justifyContent="space-between">
                    <Typography
                      fontWeight={600}
                      color="#368AD0"
                      fontSize="16px"
                    >
                      {" "}
                      Protein
                    </Typography>
                    <Box>
                      <Typography fontWeight={600} fontSize="16px">
                        {item.totalProtein || 0}/{item.outOfProtein}g
                      </Typography>
                    </Box>
                  </Box>
                  <BorderLinearProgress
                    activeColor="#368AD0"
                    background={styles.lightBlack}
                    variant="determinate"
                    value={
                      ((item.totalProtein || 0) >= item.outOfProtein
                        ? 1
                        : (item.totalProtein || 0) / item.outOfProtein) * 100
                    }
                  />
                </>
              )}
            </Box>
          </>
        ))}
      </Box>
    </Box>
  );
};

export default StatsList;
