import React from "react";
import {
  Card,
  Box,
  Typography,
  IconButton,
  useMediaQuery
} from "@mui/material";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import TotalWeightUpdate from "../Modals/TotalWeightUpdate";
import useThemeColor from "../../utils/useTheme";
import { useTheme } from "@emotion/react";

const CircularGauge = ({
  diaryId,
  levelId,
  strokeColor,
  title,
  value,
  nonEditable = false,
  levelName,
  maxValue,
  dayName,
  openWeightModal,
  setIsRefreshNeeded,
  setOpenWeightModal,
  widthX = 350, // Dynamic width
  heightX = 250 // Dynamic height
}) => {
  const isLaptop = useMediaQuery("(max-width:1090px)");
  const width = isLaptop && levelName === "level2" ? 190 : widthX;
  const height = isLaptop && levelName === "level2" ? 140 : heightX;
  // Calculate the percentage based on value and maxValue
  const percentage = (value / maxValue) * 100;
  const styles = useThemeColor();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // const isLaptop = useMediaQuery(theme.breakpoints.down("md"));
  // Calculate radius and center based on dynamic width and height
  const radius = (width - 50) / 2; // Adjust padding as needed
  const centerX = width / 2;
  const centerY = width / 2;
  const strokeWidth =
    (isMobile || isLaptop) && levelName === "level2" ? 13 : 20; // Increase stroke width to make the arc thicker

  // Define start and end angles for a semicircle
  const startAngle = -200;
  const endAngle = 20;

  // Calculate the angle for the current value
  const valueAngle = Math.min(
    startAngle + (endAngle - startAngle) * (percentage / 100),
    endAngle
  );

  // Create the SVG arc path for the background and progress
  const createArcPath = (start, end) => {
    const rad = (angle) => (angle * Math.PI) / 180;

    const startPoint = {
      x: centerX + radius * Math.cos(rad(start)),
      y: centerY + radius * Math.sin(rad(start))
    };

    const endPoint = {
      x: centerX + radius * Math.cos(rad(end)),
      y: centerY + radius * Math.sin(rad(end))
    };

    const largeArcFlag = Math.abs(end - start) > 180 ? 1 : 0;

    return `
      M ${startPoint.x} ${startPoint.y}
      A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endPoint.x} ${endPoint.y}
    `;
  };

  // Define arcs
  const backgroundArc = createArcPath(startAngle, endAngle);
  const progressArc = createArcPath(startAngle, valueAngle);

  return (
    <Card
      sx={{
        backgroundColor: styles.diaryTracker,
        boxShadow: "none !important",
        padding: 4,
        width,
        height,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Box position="relative">
        <svg width={width} height={height}>
          {/* Background Arc */}
          <path
            d={backgroundArc}
            fill="none"
            stroke={styles.trackerBackground}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />

          {/* Progress Arc */}
          <path
            d={progressArc}
            fill="none"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            style={{
              transition: "stroke-dashoffset 0.5s ease"
            }}
          />
        </svg>

        {/* Center Text */}
        <Box
          position="absolute"
          top="70%"
          left="50%"
          sx={{
            transform: "translate(-50%, -50%)",
            textAlign: "center"
          }}
        >
          <Typography
            fontSize={
              (isMobile || isLaptop) && levelName === "level2" ? "11px" : "16px"
            }
            color={styles.color}
            sx={{ opacity: 0.75 }}
          >
            {title}
          </Typography>
          <Typography
            fontSize={
              (isMobile || isLaptop) && levelName === "level2" ? "22px" : "44px"
            }
            color={styles.color}
            fontWeight="bold"
          >
            {value}g
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            gap="5px"
            justifyContent={"center"}
          >
            <Typography
              fontSize={
                (isMobile || isLaptop) && levelName === "level2"
                  ? "12px"
                  : "14px"
              }
              color="#8E8E8E"
            >
              out of
            </Typography>
            <Typography
              fontSize={
                (isMobile || isLaptop) && levelName === "level2"
                  ? "10px"
                  : "14px"
              }
              color="#368AD0"
            >
              {maxValue}g
            </Typography>
            {!nonEditable && (
              <IconButton
                sx={{
                  borderRadius: "50%",
                  height:
                    (isMobile || isLaptop) && levelName === "level2" ? 16 : 20,
                  width:
                    (isMobile || isLaptop) && levelName === "level2" ? 16 : 20,
                  padding: "2px",
                  svg: {
                    path: {
                      fill: "#368AD0"
                    }
                  }
                }}
                onClick={() => {
                  setOpenWeightModal(title);
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        </Box>
        <TotalWeightUpdate
          levelName={levelName}
          title={title}
          setIsRefreshNeeded={setIsRefreshNeeded}
          diaryId={diaryId}
          levelId={levelId}
          maxValue={maxValue}
          openWeightModal={openWeightModal}
          setOpenWeightModal={setOpenWeightModal}
          dayName={dayName}
        />
      </Box>
    </Card>
  );
};

export default CircularGauge;
