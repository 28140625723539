import { Close } from "@mui/icons-material";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import React from "react";

const FreeTrialModal = ({
  open,
  onClose,
  openSubscriptionModal,
  isMobile = false,
  disabledFreeTrial
}) => {
  return isMobile ? (
    open ? (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1300,
          overflowY: "auto"
        }}
      >
        <Box
          sx={{
            position: "relative",
            top: "60%",
            left: "50%",
            overflow: "auto",
            width: { md: "42%", xs: "100%" },
            borderRadius: 2,
            transform: "translate(-50%, -50%)"
          }}
        >
          <Box
            sx={{
              padding: { xs: "28px", md: 0 },
              position: "relative",
              bgcolor: "#000",
              borderRadius: "16px 16px 0 0",
              boxShadow: 24,
              overflow: "hidden"
            }}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: "url(/images/sky.png)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                opacity: { xs: 1, md: 0.5 },
                zIndex: 1
              }}
            />
            <Box
              sx={{
                position: "relative",
                zIndex: 2
              }}
            >
              <Box
                display="flex"
                justifyContent="end"
                alignItems="flex-end"
                width="100%"
              >
                {" "}
                <IconButton
                  sx={{
                    svg: {
                      fill: "#FCFCFC"
                    }
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClose();
                  }}
                >
                  <Close />
                </IconButton>
              </Box>
              <Box
                sx={{ padding: { sm: 0, md: "0 95px" } }}
                display={"flex"}
                gap="10px"
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box
                  component="img"
                  src="/images/threepillars.png"
                  alt="base"
                  sx={{
                    width: "68px",
                    height: "68px",
                    objectFit: "cover"
                  }}
                />
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap="10px"
                  flexDirection="column"
                >
                  <Typography
                    fontWeight={600}
                    fontSize={"24px"}
                    color="#FCFCFC"
                  >
                    Welcome to the Three Pillars Method Platform
                  </Typography>
                  <Typography mt={-1} fontSize={"14px"} color="#FCFCFC">
                    Your 5 Day Free Trial starts now! Take this time to explore
                    all the platform has to offer.
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent={"center"}
                    mt={2}
                    alignItems="center"
                    gap={2}
                  >
                    <Typography fontSize={24}>🥗</Typography>
                    <Typography fontSize={"14px"} color="#FCFCFC">
                      <b>Meals of the day (MODs): </b> Get inspired with our
                      daily MODs and share yours with the community.
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent={"center"}
                    alignItems="center"
                    gap={2}
                  >
                    <Typography fontSize={24}> 🔊</Typography>
                    <Typography fontSize={"15px"} color="#FCFCFC">
                      <b>5 Days of the #800gChallenge Program: </b> Each day
                      will unlock a new training from EC’s #800gChallenge.
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent={"center"}
                    alignItems="center"
                    gap={2}
                  >
                    <Typography fontSize={24}> 📒</Typography>
                    <Typography fontSize={"15px"} color="#FCFCFC">
                      <b> Simplified logging:</b> Begin tracking your daily
                      eating habits as you strive for 800g of fruits & veggies
                      each day.
                    </Typography>
                  </Box>
                  <Typography mt={2} fontSize={"15px"} color="#FCFCFC">
                    At the end of your free trial you can subscribe to have
                    continued access to MODs / Daily Nutrition Logging, and
                    unlock additional Training Programs.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            //   height={"40%"}
            sx={{
              background: "#333333",
              display: "flex",
              padding: "24px 47px",
              borderRadius: "0 0 16px 16px",
              flexDirection: "column"
            }}
          >
            <Box mt={2} display="flex" justifyContent="center">
              <Button
                disabled={disabledFreeTrial}
                sx={{
                  width: "70%",
                  color: "#368AD0",
                  textTransform: "none",
                  fontWeight: "bold"
                }}
                variant="none"
                onClick={onClose}
              >
                Continue Free Trial
              </Button>
            </Box>{" "}
            <Box mt={2}>
              <Button
                sx={{
                  background: "#368AD0",
                  color: "#FCFCFC",
                  textTransform: "none",
                  width: "100%",
                  // height: "54px"
                }}
                onClick={() => {
                  onClose();
                  openSubscriptionModal();
                }}
              >
                Purchase Plan Now
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    ) : (
      <></>
    )
  ) : (
    <Modal open={open} onClose={onClose} disableAutoFocus disableEnforceFocus>
      <Box
        sx={{
          position: "absolute",
          top: "30%",
          left: "50%",
          // overflow: "auto",
          height: { xs: "70%", md: "45%" },
          width: { md: "42%", xs: "100%" },
          borderRadius: 2,
          border: "none",
          transform: "translate(-50%, -50%)"
        }}
      >
        <Box
          sx={{
            padding: { xs: "28px", md: 0 },
            position: "relative",
            bgcolor: "#000",
            borderRadius: "16px 16px 0 0",
            boxShadow: 24,
            overflow: "hidden" // Ensures pseudo-element stays within the box
          }}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          //   height={"65%"}
          flexDirection={"column"}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: "url(/images/sky.png)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              opacity: { xs: 1, md: 0.5 }, // Set the opacity for the background image
              zIndex: 1 // Ensure it is behind the content
            }}
          />
          <Box
            sx={{
              position: "relative",
              zIndex: 2 // Content stays on top of the background
            }}
          >
            <Box
              display="flex"
              justifyContent="end"
              alignItems="flex-end"
              width="100%"
            >
              <IconButton
                sx={{
                  svg: {
                    fill: "#FCFCFC"
                  }
                }}
                onClick={onClose}
              >
                <Close />
              </IconButton>
            </Box>
            <Box
              sx={{ padding: { sm: 0, md: "10px 50px" } }}
              display={"flex"}
              gap="10px"
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box
                component="img"
                src="/images/threepillars.png"
                alt="base"
                sx={{
                  width: "68px",
                  height: "68px",
                  objectFit: "cover"
                }}
              />
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                flexDirection="column"
              >
                <Typography fontWeight={600} fontSize={"24px"} color="#FCFCFC">
                  Welcome to the Three Pillars Method Platform
                </Typography>
                <Typography mt={-1} fontSize={"14px"} color="#FCFCFC">
                  Your 5 Day Free Trial starts now! Take this time to explore
                  all the platform has to offer.
                </Typography>
                <Box
                  display="flex"
                  justifyContent={"center"}
                  alignItems="center"
                  gap={2}
                  mt={2}
                >
                  <Typography fontSize={22}>🥗</Typography>
                  <Typography fontSize={"15px"} color="#FCFCFC">
                    <b>Meals of the day (MODs): </b> Get inspired with our daily
                    MODs and share yours with the community.
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent={"center"}
                  alignItems="center"
                  gap={2}
                >
                  <Typography fontSize={22}> 🔊</Typography>
                  <Typography fontSize={"15px"} color="#FCFCFC">
                    <b>5 Days of the #800gChallenge Program: </b> Each day will
                    unlock a new training from EC’s #800gChallenge.
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent={"center"}
                  alignItems="center"
                  gap={2}
                >
                  <Typography fontSize={22}> 📒</Typography>
                  <Typography fontSize={"15px"} color="#FCFCFC">
                    <b> Simplified logging:</b> Begin tracking your daily eating
                    habits as you strive for 800g of fruits & veggies each day.
                  </Typography>
                </Box>
                <Typography mt={2} fontSize={"14px"} color="#FCFCFC">
                  At the end of your free trial you can subscribe to have
                  continued access to MODs / Daily Nutrition Logging, and unlock
                  additional Training Programs.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          height={"40%"}
          sx={{
            background: "#333333",
            display: "flex",
            padding: "24px 47px",
            borderRadius: "0 0 16px 16px",
            flexDirection: "column"
          }}
        >
          <Box mt={1} display="flex" justifyContent="center">
            <Button
              disabled={disabledFreeTrial}
              sx={{
                width: "70%",
                color: "#368AD0",
                textTransform: "none",
                fontWeight: "bold"
              }}
              variant="none"
              onClick={onClose}
            >
              Continue Free Trial
            </Button>
          </Box>{" "}
          <Box mt={2} display="flex" justifyContent="center">
            <Button
              sx={{
                width: { xs: "100%", md: "70%" },
                backgroundColor: "#368AD0",
                textTransform: "none",
                fontWeight: "bold"
              }}
              variant="contained"
              // onClick={handleSubscribe}
              onClick={() => {
                onClose();
                openSubscriptionModal();
              }}
            >
              Purchase Plan Now
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default FreeTrialModal;
